<template>
  <div id="mappingBrowserContainer" ref="mappingBrowserContainer">
    <div class="mappingBrowserComponentsContainer"
    @keyup="mapKeyUp"
    tabindex="0"
    >
      <template v-if="loaded">                            
        <ProjectSaveLoadDialog
        ref="ProjectSaveLoadDialog"        
        />                       
        <template v-if="selectedFeatures.length > 1">
          <MultiProperties                            
          title="PROPERTIES"
          :selectedValues="selectedFeatures"          
          @openMenuItem="$emit('openMenuItem', $event)" 
          @closeMenuItem="$emit('closeMenuItem', $event)"
          @deleteFeatures="deleteFeatures($event)"
          @goto="gotoFeatures($event)"
          @gotoAndZoomToFit="gotoFeaturesAndZoomToFit($event)"
          >
          </MultiProperties>          
        </template>
        <template v-if="selectedFeatures.length <= 1">
          <Properties                  
          :itemsForSelection="itemsForPropertyWindow"       
          :propertiesTypes="['feature', 'layer']"          
          :selectedValueProp="propertiesWindowSelectedValue"
          @selectedItemChanged="propertiesWindowSelectedItemChanged"         
          @openMenuItem="$emit('openMenuItem', $event)" 
          @closeMenuItem="$emit('closeMenuItem', $event)"
          >          
            <template slot="feature">
              <FeatureProperties                                     
              :geometryExpanded="featurePropertiesGeometryExpanded"
              ref="featureProperties"
              :locationVisibleOnMap="locationVisibleOnMap"
              :lengthVisibleOnMap="lengthVisibleOnMap"           
              :sideLengthsVisibleOnMap="sideLengthsVisibleOnMap" 
              :areaVisibleOnMap="areaVisibleOnMap"            
              :lengthUnit="lengthUnit"
              :areaUnit="areaUnit"
              :coordinateMode="coordinateMode"
              :createActive="activePointerTool === 'createFeature'"
              :createGeometryType="createFeatureGeometryType"
              :feature="(selectedFeatures.length === 1) ? selectedFeatures[0] : null"
              :menuItems="menuItems"                                  
              :useAsDefaultEnabled="useAsDefaultFeatureEnabled"            
              @activate="createFeatureActivated"          
              @geometryTypeChanged="createFeatureGeometryTypeChanged"
              @openMenuItem="$emit('openMenuItem', $event)" 
              @closeMenuItem="$emit('closeMenuItem', $event)"           
              @showHelp="showHelp"         
              @goto="gotoFeature"
              @gotoAndZoomToFit="gotoFeatureAndZoomToFit"
              @addBookmark="addFeatureBookmark"        
              @useAsDefault="useAsDefault"
              @delete="deleteFeatures([$event])"
              @locationVisibleOnMapChanged="locationVisibleOnMap = $event"
              @lengthVisibleOnMapChanged="lengthVisibleOnMap = $event"            
              @sideLengthsVisibleOnMapChanged="sideLengthsVisibleOnMap = $event"     
              @areaVisibleOnMapChanged="areaVisibleOnMap = $event"            
              @lengthUnitChanged="lengthUnit = $event"            
              @areaUnitChanged="areaUnit = $event"        
              @geometryExpandedChange="featurePropertiesGeometryExpanded = $event"
              @layerInfo="layerInfo"
              >
              </FeatureProperties>
            </template>        
            <template slot="layer">
              <LayerProperties                                               
              ref="layerProperties"              
              :layer="selectedLayer"
              :menuItems="menuItems"                        
              :isLoadingMeta="isLoadingMeta"                       
              @openMenuItem="$emit('openMenuItem', $event)" 
              @closeMenuItem="$emit('closeMenuItem', $event)"           
              @showHelp="showHelp"              
              @layerVisibilityToggle="setLayerVisible($event, !$event.visible)"          
              >
              </LayerProperties>
            </template>
          </Properties>
        </template>
        <IdentifyResults
          ref="identifyResults"        
          :identifyFeature="identifyFeature"
          :bufferSize="identifyBufferSize"
          :bufferUnit="identifyBufferUnit"
          :layerItems="identifyLayerItems"
          :features="featuresInSelectionRange"
          :selectedLayerItemId="identifyLayerItemId"
          :isIdentifying="isIdentifying"
          @selectedLayerItemIdChanged="selectedActiveLayerItemIdChanged($event)"        
          @selectedFeatureChangeRequest="identifyResultsSelectFeatures($event)"        
          @filteredAndSortedFeaturesChanged="filteredAndSortedFeaturesChanged($event)"
          @goto="gotoFeature"
          @gotoAndZoomToFit="gotoFeatureAndZoomToFit"
          @addBookmark="addFeatureBookmark"
          @showHelp="showHelp"
          @openMenuItem="$emit('openMenuItem', $event)" 
          @closeMenuItem="$emit('closeMenuItem', $event)"
          @zoomToFitIdentifyRange="zoomToFitIdentifyRange"
          @zoomToFitFeatures="zoomToFitFeatures"
          @sortedFieldChanged="identifyResultsSortedFieldChanged"
          @fieldFilterChanged="identifyResultsFieldFilterChanged" 
          @delete="deleteFeatures($event)"
          @bufferUnitChanged="identifyBufferChanged(identifyBufferSize, $event)"
          @bufferSizeChanged="identifyBufferChanged($event, identifyBufferUnit)"      
          @layerInfo="layerInfo"              
        >  
        </IdentifyResults>
        <CreateBookmarkDialog
          ref="CreateBookmarkDialog"
          :dialogTitle="createBookmarkDialogTitle"
          :scales="availableScales"
          :thumbnailUrl="bookmarksThumbNailSourceUrl"
          :coordinateMode="coordinateMode"         
          :bookmarks="bookmarks"
          :bookmarkName="createBookmarkName"
          :mapCoords="createBookmarkCoords"        
          :mapScale="mapView.scale"
          :feature="featureForCreateBookmark"          
        />        
        <MapContextMenu 
          v-on-downaway="contextMenuDownAway"         
          :gotoMenuTitle="contextMenuGotoTitle"
          @pinBookmark="pinBookmark"            
          @copyCoordsToClipboard="copyCoordsToClipboard"                     
          v-show="contextMenuPopoverVisible"          
          :showDeleteLocationPinMenuOption="showDeleteLocationPinMenuOption"
          :showIdentifyInSelectionMenuOption="showIdentifyInSelectionMenuOption"
          :showMapSheetOption="showMapSheetOption"
          :coords="contextMenuCoords"          
          @deleteLocationPin="deleteLocationPin"
          @identifyInSelection="identifyInSelectedFeature"
          @googleMaps="googleMaps"
          @bingMaps="bingMaps"
          @streetView="streetView"
          @openMapSheet="openMapSheet"
          @clearSelection="contextMenuClearSelection"
          @goto="gotoLocation"
          @setLocationMarker="pinLocation(mapView.toMap($event))"
          @close="contextMenuPopoverVisible = false"
        >
        </MapContextMenu>
        <Help 
        :helpUrl="helpUrl"
        :urlBase="helpUrlBase"
        @openMenuItem="$emit('openMenuItem', $event)" 
        @closeMenuItem="$emit('closeMenuItem', $event)"> 
        </Help> 
        <Search 
          ref="search"                                        
          :searchResultsMessage="searchResultsMessage"          
          :menuItems="menuItems"
          :searchItems="searchItems"          
          :selectedSearchItemId="selectedSearchItemId"
          :isSearching="isSearching"
          :searchResultFeatures="searchResults"
          :message="searchMessage"
          :searchText="searchText"                  
          @showHelp="showHelp"
          @openMenuItem="$emit('openMenuItem', $event)" 
          @closeMenuItem="$emit('closeMenuItem', $event)"           
          @selectedSearchItemChanged="selectedSearchItemChanged"
          @searchTextChanged="searchTextChanged"          
          @pinBookmark="addFeatureBookmark"
          @goto="gotoFeature"
          @gotoAndZoomToFit="gotoFeatureAndZoomToFit"          
          @selectFeatures="searchSelectFeatures"          
          >
        </Search>        
        <LayerManager
          ref="layerManager"          
          :menuItems="menuItems"
          :layers="layersForLayerManager"        
          :showLegendSymbology="showLegendSymbologyInLayerManager"
          @layerVisibilityChange="setLayerVisible($event.subLayer, $event.visible)"            
          @reorderLayerItem="reorderLayerItem($event.item, $event.sibling)"  
          @showHelp="showHelp"      
          @openMenuItem="$emit('openMenuItem', $event)" 
          @closeMenuItem="$emit('closeMenuItem', $event)" 
          @importIntoLayer="importIntoLayer"  
          @deleteLayer="deleteLayer"    
          @editLayer="doEditLayer"
          @info="layerInfo"
        >        
        </LayerManager>              
        <BasemapManager          
          :basemaps="basemaps" 
          :useExtendedScales="useExtendedScales"   
          @onBasemapChange="$emit('onBasemapChange', $event)"          
          @showHelp="showHelp"
          @openMenuItem="$emit('openMenuItem', $event)" 
          @closeMenuItem="$emit('closeMenuItem', $event)" 
          >
        </BasemapManager> 
        <BookmarkManager          
          :coordinateMode="coordinateMode"
          :bookmarks="bookmarks"
          @goto="gotoBookmark"
          @pinBookmarkCenter="pinBookmarkCenter"
          @showHelp="showHelp"
          @openMenuItem="$emit('openMenuItem', $event)" 
          @closeMenuItem="$emit('closeMenuItem', $event)" 
          >
        </BookmarkManager>        
        <ToolLegend 
          :subLayers="legendSubLayers"
          :searchSubLayer="selectedSearchSubLayer"
          @showHelp="showHelp"
          @openMenuItem="$emit('openMenuItem', $event)" 
          @closeMenuItem="$emit('closeMenuItem', $event)" 
          >
        </ToolLegend>                                        
        <MapStatusBar          
          :editingCoords="editingCoords"
          :availableScales="availableScales"
          :scalebarVisible="scalebarVisible"
          :compassVisible="compassVisible"
          :navigationVisible="navigationVisible"
          :overviewVisible="overviewVisible"
          :coordinateMode="coordinateMode"
          :is3D="is3D"
          :locationPin="locationPin"
          :selectionOutlineColourProp="selectionOutlineColour"  
          :searchExtent="searchInclusionExtent"  
          @selectionOutlineColourChange="selectionOutlineColourChange"
          @setMapScale="setMapScale"
          @toggleUIElementVisibiliy="toggleUIElementVisibiliy"          
          @gotoCurrentPosition="gotoCurrentPosition"
          @coordinateModeChanged="coordinateModeChanged"
          @goto="goto"
          @pinLocation="pinLocation"       
          @editingMapCoords="editingCoords = $event"   
          @setFullScreen="$emit('setFullScreen', $event)"
          >
        </MapStatusBar>                            
      </template>
      <div class="componentsContainerWithPadding pointer-events-none" :style="componentsContainerPaddingOffsets">
        <div class="componentsContainerInner pointer-events-none">
          <template v-if="loaded">                       
            <div class="topLeftWidgets d-flex">
              <TopLeftToolbar
              class="mr-4 pointer-events-auto"   
              :previousEnabled="previousEnabled"
              :nextEnabled="nextEnabled"
              @previousItem="previousItem"           
              @nextItem="nextItem"                         
              />              
            </div>            
            <div class="bottomLeftWidgets d-flex flex-column">
              <MapScaleBar v-if="scalebarVisible">                            
              </MapScaleBar>            
              <MapCopyright 
              class="mt-1"
              v-show="copyrightVisible" :copyrights="copyrights"
              ></MapCopyright>                    
            </div>            
            <div class="topRightWidgets d-flex">
              <TopRightToolbar
              class="mr-4 pointer-events-auto"
              :activePointerTool="activePointerTool"
              :activeDeleteAll="activeDeleteAll"
              :identifyShape="identifyGeometryType"
              :measureShape="measureGeometryType"
              :createFeatureShape="createFeatureGeometryType"
              :deleteType="deleteType"
              :canUndoDelete="deletionHistory.length > 0"              
              @activePointerToolChanged="activePointerTool = $event"       
              @activeDeleteAllChanged="activeDeleteAll = $event"       
              @identifyShapeChanged="identifyGeometryType = $event"
              @measureShapeChanged="measureGeometryType = $event"
              @createFeatureShapeChanged="createFeatureGeometryType = $event"
              @deleteTypeChanged="deleteType = $event"
              @deleteAll="deleteAll"              
              @undoDelete="undoDelete"
              />
              <div class="d-flex flex-column align-items-center pointer-events-auto">
                <MapCompass v-show="compassVisible"                
                @setViewRotation="setViewRotation">
                </MapCompass>
                <MapNavigation 
                class="mt-2"
                v-show="navigationVisible"          
                @zoom="zoom">
                </MapNavigation>
              </div>              
            </div>            
            <MapOverview  
              class="pointer-events-auto"
              :arcGISBaseUrl="arcGISBaseUrl"
              v-show="overviewVisible"
              :basemaps="overviewBasemaps"
              :value="activeOverviewBasemap"                            
              :parentMapViewLeftOffset="componentsLeft"
              :parentMapViewTopOffset="componentsTop"
              :parentMapViewRightOffset="componentsRight"
              :parentMapViewBottomOffset="componentsBottom"
              :isFullScreen="isFullScreen"
              @input="activeOverviewBasemap = $event">
            </MapOverview>            
          </template>          
        </div>        
      </div>
      <button 
      v-if="isFullScreen"
      @click="$emit('setFullScreen', false)" 
      v-b-popover.hover.top="'Fullscreen'" 
      class="fullScreenButton" 
      >
        <font-awesome-icon icon="expand-arrows-alt"/>
    </button>	
    </div>        
    <Settings
    :featuresLabelSymbol="featuresLabelSymbol"
    @openMenuItem="$emit('openMenuItem', $event)" 
    @closeMenuItem="$emit('closeMenuItem', $event)">
    </Settings>    
    <ErrorDialog    
    ref="ErrorDialog"
    :title="errorTitle" 
    :details="errorMessage"/>   
    <ExportPresentedDialog
      ref="exportPresentedDialog"      
      :forceUpdate="exportPresentedForceUpdate"
      :basemaps="basemaps"      
      :template="exportTemplate"
      :layout="exportLayout"
      :format="exportFormat"
      :title="exportTitle"      
      @export="exportPresented"
    />
    <LayerImportDialog
    :agsPoint="agsPoint"
    :defaults="defaults"
    ref="ImportLayerDialog"
    :invalidMessageFn="layerEditValidation"
    :file="layerImportFile"
    :defaultCoordType="coordinateMode === 'xyNonPrecise' ? 'xy': coordinateMode"
    @ok="layerImportDialogOk"       
    />
    <LayerEditDialog
    ref="EditLayerDialog"
    :layer="editLayer"    
    :invalidMessageFn="layerEditValidation"
    @ok="layerEdited($event)"
    />
    <WaitDialog     
    ref="WaitDialog"
    spinner
    >
      <div class="d-flex flex-column">
        <div class="d-flex align-items-center">   
          <font-awesome-icon icon="map-marked" class="mr-2"/>             
          Exporting presented map... (please enable any popups if prompted).       
        </div>            
      </div>
    </WaitDialog>
    <ConfirmationDialog
    id="deleteLayerConfirmationDialog"
    title="Delete layer"
    icon="layer-group"
    :data="confirmationDialogData"
    :message="confirmationDialogMessage"
    @yes="deleteConfirmed"
    /> 	    
  </div>   
</template>

<script>
import Vue from "vue";
import LayerImportDialog from "./LayerImportDialog.vue"
import LayerEditDialog from "./LayerEditDialog.vue"
import OsGridRef, { LatLon, Dms } from "./util/geodesy/osgridref.js";
import moment from "moment";
import { loadScript, loadModules } from "esri-loader";
import Help from "./Help.vue"
import LayerManager from "./LayerManager.vue";
import ToolLegend from "./Legend.vue";
import ConfirmationDialog from "./shared/ConfirmationDialog.vue"

import Search from "./Search.vue";
import Properties from "./Properties.vue";
import MultiProperties from "./MultiProperties.vue";
import FeatureProperties from "./FeatureProperties.vue";
import LayerProperties from "./LayerProperties.vue";
import BasemapManager from "./BasemapManager.vue";
import BookmarkManager from "./BookmarkManager.vue";
import MapNavigation from "./MapNavigation.vue";
import MapStatusBar from "./MapStatusBar.vue";
import MapOverview from "./MapOverview.vue";
import MapCopyright from "./MapCopyright.vue";
import MapCompass from "./MapCompass.vue";
import MapScaleBar from "./MapScaleBar.vue"
import MapContextMenu from "./MapContextMenu.vue";
import TopLeftToolbar from "./TopLeftToolbar.vue";
import TopRightToolbar from "./TopRightToolbar.vue";
import CreateBookmarkDialog from "./CreateBookmarkDialog.vue"
import ProjectSaveLoadDialog from "./ProjectSaveLoadDialog.vue"
import ToggleButton from "./menus/ToggleButton.vue"
import IdentifyResults from "./IdentifyResults.vue"
import { directive as onDownAway } from './shared/DownAwayDirective.js';
import axios from 'axios';
import DropdownSelect from "./shared/DropdownSelect.vue";
import ErrorDialog from './shared/ErrorDialog.vue';
import WaitDialog from './shared/WaitDialog.vue';
import { LocatorHub } from "./EsriLocatorHub.js";
import { OSPlaces } from "./shared/OSPlaces.js";

import {agsPointToCoordString, 
        getGeoLocationCurrentPosition, 
        latLonToAgsPoint,
        getPolylineDistance,          
        coordStringToAgsPoint, 
        isValidCoordString,        
        distanceBetweenPoints,
        getPointAlongPolyline
        } from "./SpatialUtils.js";

import OrderedAxios from "./shared/OrderedAxios.js"
import { default as MappingBrowserPersistance } from "./MappingBrowserPersistance.vue"
import { default as CreatedFeatureSelection } from "./CreatedFeatureSelection.vue"
import createdFeatureSelection from './CreatedFeatureSelection.vue';
import { default as FeatureMixin } from "./FeatureMixin.vue";
import Settings from './Settings.vue';
import ExportPresentedDialog from "./ExportPresentedDialog.vue";

export default {
  components: {             
      ConfirmationDialog,
      LayerImportDialog,
      LayerEditDialog,
      ExportPresentedDialog,
      Help,
      MapNavigation, 
      MapStatusBar, 
      MapOverview, 
      MapCopyright, 
      MapCompass, 
      MapContextMenu, 
      TopLeftToolbar,
      TopRightToolbar,
      LayerManager, 
      BasemapManager, 
      BookmarkManager,         
      MapScaleBar,
      CreateBookmarkDialog,      
      ToggleButton,
      IdentifyResults,
      DropdownSelect,
      Properties,
      FeatureProperties,      
      LayerProperties,
      ProjectSaveLoadDialog,
      ErrorDialog,      
      WaitDialog,      
      ToolLegend,                        
      Search,
      Settings,            
      MultiProperties
  },
  mixins: [ MappingBrowserPersistance, CreatedFeatureSelection, FeatureMixin ],
  directives: {
      onDownaway: onDownAway,
  },
  props: {
    arcGISBaseUrl: {
      type: String
    },
    msal: {
      type: Object
    },
    networkInterceptors: {
      type: Object,
    },
    canCreateLayers: {
      type: Boolean
    },        
    useExtendedScales: {
      type: Boolean
    },    
    mapExtent: {
      type: Object,      
    },
    searches: {
      type: Array,      
      required: true
    },
    menuItems: {
      type: Object,
    },
    componentsLeft: {
      type: Number,
      default: 0
		},
		componentsTop: {
      type: Number,
      default: 0
		},    
		componentsRight: {
      type: Number,
      default: 0
		},    
		componentsBottom: {
      type: Number,
      default: 0
		},    
		isFullScreen: {
			type: Boolean,
			default: false
    },
    showLegendSymbologyInLayerManager: {
      type: Boolean,
			default: true
    },
    basemaps: {
      type: Array,
      required: true
    },
    layersConfig: {
      type: Array,
      requied: true
    },    
    bookmarksThumbNailSourceUrl: {
      type: String,
      required: true
    },
    helpUrlBase: {
        default: "",
        type: String,
        required: true
    }, 
  },
  data() {
    return {            
      isLoadingMeta: false,           
      metaCount: 0,
      hasMeta: 0,
      noMeta: 0,      
      ctrlkeyDown: false,
      extentPadding: 1.05,
      featurePropertiesGeometryExpanded: false,       
      activeDeleteAll: "inView",
      processSVMDelete: false,
      deletionHistory: [],
      processClick: false,      
      agsFeatureToVueFeature: null,
      confirmationDialogMessage: "",
      confirmationDialogIcon: null,
      confirmationDialogData: null,
      osAddressSearchId: 200,
      osAddressLayerId: 0,
      osAddressLayerGlobalId: "os_",
      editLayer: null,
      agsGeometryJsonUtils: null,
      agsSymbolJsonUtils: null,
      defaultLayerEditSymbol: {
        style: "square",    
        color: "yellow",
        size: "8px",  // pixels          
      },
      exportPresentedForceUpdate: false,
      layerImportFile: null,
      editingCoords: false,
      contextMenuCoords: null,
      touchDownCount: 0,
      lastPointerDown: null,
      lastPointerDownMapPoint: null,
      contextMenuTimeOut: null,
      events: [],                                         
      navIndex: -1,
      navHistory: [],
      exportTemplate: "NCC",
      exportLayout: "A4_Landscape",
      exportFormat: "pdf",      
      exportTitle: "",      
      agsPrintTemplate: null,
      agsPrintParameters: null,      
      print: null,
      identifyBufferSize: 0,
      identifyBufferUnit: "meters",
      identifyGeometryType: "rectangle",
      showDeleteLocationPinMenuOption: false,
      showIdentifyInSelectionMenuOption: false,
      showMapSheetOption: false,
      featuresLabelSymbol: {              
        type: "text",
        lineHeight: 1,
        lineWidth: 512,
        horizontalAlignment: "center",
        verticalAlignment: "middle",
        kerning: false,
        text: "text",
        color: [255, 255, 255, 1],               
        haloColor: [0, 0, 0, 1],
        haloSize: 1,
        font: {
          style: "normal",            
          weight: "bold",
          family: "sans-serif",          
          size: 16
        }          
      },        
      locationPin: null,                      
      measurements: [],
      annotations: [],
      locationVisibleOnMap: false,
      lengthVisibleOnMap: false,      
      sideLengthsVisibleOnMap: false,
      areaVisibleOnMap: false,
      lengthUnit: "meters",
      areaUnit: "square-meters",      
      userFeaturesGfxLayer: null,
      agsGeometryEngine: null,
      agsSketchViewModel: null,
      pointerDownHappened: false,      
      createFeatureGeometryType: "polyline",
      deleteType: "all",
      measureGeometryType: "polyline",
      selectionOutlineColour: [255, 255, 255, 1],
      searchResultsMessage: null,
      searchInclusionExtent:null,
      createBookmarkDialogTitle: "",
      featureForCreateBookmark : null,
      mbAxios: {},
      renderLayers: {},
      osSearches: [],
      locatorSearches: [],      
      subLayerSearches: [],      
      addressLegendItem: null,                      
      breakCounter: 0,
      //active view state
      availableScales: [500, 1000, 1250, 2000, 2500, 4000, 8000, 16000, 32000, 64000, 128000, 256000, 512000],            
      activeOverviewBasemap: null,      
      container: "mappingBrowserContainer",
      mbMapView: null,      
      viewHandles: [], 
      layers: [],                  
      tools:[],
      initialViewpoint: null,
      //overviewMapHandler: null, //not wired up until mapview first displayed. (when())  
      //subLayerLookupTable: null,

      viewInteractionMode:null,                

      MINIMUM_SEARCH_TEXT_LENGTH: 3,  
      loading: false,
      agsMapView:()=>{},
      agsCircle: null,
      agsPoint:null, 
      agsPolyline: null,      
      agsGeometry: null,
      agsMapImageLayer:null,      
      agsIdentifyParameters:null,
      agsIdentify:null,
      
      
      //      
      agsGraphic:null,
      agsPolygon: null,
      agsGraphicsLayer: null,      
      agsWebScene:null,    
      agsQuery: null,
      agsQueryTask: null,       
      agsFeatureLayer: null,

      agsSimpleMarkerSymbol: null,
      agsPictureMarkerSymbol: null,
      agsTextSymbol: null,
      agsSimpleLineSymbol: null,      
      agsSimpleFillSymbol: null,
      agsPictureFillSymbol: null,            
      agsPromiseUtils: null,
      agsViewpoint: null,
      agsTileInfo: null,
      bookmarkGeometry: null,
      bookmarkSymbol: null,      
      locationSearchSymbol: null,       
      identifyGfxLayer: null,
      bookmarksGfxLayer: null,
      measurementsGfxLayer: null,
      userFeaturesLayer: null,      
      searchFeaturesGfxLayer: null,
      svmUpdateLayer: null,
      updateLabelsGfxLayer: null,
      svmCreateLayer: null,      
      selectedSystemFeaturesGfxLayer: null,            
      userFeaturesLayer: null,
      userFeaturesLabelsGfxLayer: null,      
      annotationLinesGfxLayer: null,
      annotationEndsGfxLayer: null,
      annotationLabelsGfxLayer: null,

      createBookmarkName: null,
      createBookmarkCoords: {
        x: 0,
        y: 0,
      },                    
      clicked: false,
      identifyLayerItemId: null,              
      clickedFeatures: [],                  
      isIdentifying: false,      
      createBookmarkPopoverVisible: false,
      contextMenuPopoverVisible: false,
      activePointerTool: 'defaultTool',                 
      clickedWithoutMove: false,
      loaded: false,
      idToFeatureMap: {},
      helpUrl:null,      
      bookmarks: [],      
      orderedAxios: new OrderedAxios(),
      helpUrl:"",
      overviewVisible : false,
      copyrightVisible : true,
      compassVisible : true,
      navigationVisible : true,
      scalebarVisible : true,
      is3D: false,
      coordinateMode : "xyNonPrecise",      
      searchMessage: "",
      isSearching: false,
      selectedSearchItemId: null,
      searchText: "",      
      errorTitle: "",
      errorMessage: "",
      latestClickIdentifyFeaturesPromise: null,
      latestRangeIdentifyFeaturesPromise: null,
      latestSelectedFeatureChangeRequestPromise: null,      
      templateRegEx: new RegExp(/\${(.*?)}/g)
    }
  },

  computed: {           
    
    allDrawnItems() {
      let labels = this.annotations.map(feature => feature.end);
      return [...this.userFeatures, ...this.measurements, ...labels];             
    },

    drawnItemsInView() {      
      return this.allDrawnItems.filter(feature => this.mapView.extent.contains(feature.geometry.extent ? feature.geometry.extent : feature.geometry));      
    },

    selectedLayer() {
      return this.selectedLayers.length === 1 ? this.selectedLayers[0] : null;
    },

    selectedLayers() {
      return this.allSublayers.filter(x => x.isSelected);
    },

    previousEnabled() {
      return this.navIndex > 0 || (this.navIndex == 0 && this.selectedFeatures.length === 0)
    },

    nextEnabled() {
      return (this.navIndex < this.navHistory.length - 1) && (this.selectedFeatures.length > 0);      
    },

    visibleActiveSubLayers() {
      return this.allSublayers.filter(subLayer => this.isVisibleActive(subLayer));      
    },

    nonVisibleActiveSubLayers() {
      return this.allSublayers.filter(subLayer => !this.isVisibleActive(subLayer));      
    },

    visiblePrivateLayers() {
      return this.privateLayersGroup.sublayers.filter(layer => layer.visible);
    },

    visiblePrivateFeatures() {
      let features = [];
      this.visiblePrivateLayers.forEach(layer => {
        features = [...features, ...layer.features];
      })      
      return features;
    },

    privateLayersGroup() {
      return this.layers.find(layer => layer.id === "privateLayers")
    },

    contextMenuGotoTitle() {

      let title = "Goto (";

      if ((this.coordinateMode === "xyNonPrecise") ||
          (this.coordinateMode === "xy")) {
        title += "Easting, Northing"          
      }

      if (this.coordinateMode === "latlon") {      
        title += "Lat, Lon"        
      }

      if (this.coordinateMode === "gridref") {          
        title += "Grid Ref"        
      }

      return title + ")..."    
    },
    
    identifyResultsGripToolTip() {
      let isOpen = this.menuItems["results"];        
      if (isOpen) {
        return "Collapse Features (or drag to resize)"
      } else {
        return "Expand Features"
      }
    },

    selectionGeometry() {      
      if (this.identifyFeature) {
        return this.identifyFeature.bufferGraphic ? this.identifyFeature.bufferGraphic.geometry : this.identifyFeature.agsFeature.geometry
      }
      return null;
    },

    annotationEnds() {
      return this.annotations.map(annotation => annotation.end);
    },

    annotationLines() {
      return this.annotations.filter(annotation => annotation.line).map(annotation => annotation.line);
    },

    userFeatures() {
      return this.userFeaturesLayer.features;
    },

    allSystemLayerFields() {      
      let fields = [];
      this.allSublayers.forEach(subLayer => {
        fields = [...fields, subLayer.fields];
      })
      return fields;
    },

    selectedSystemFeatures() {
      return this.selectedFeatures.filter(feature => feature.type !== "userFeature" && 
                                                     feature.type !== "measurement" && 
                                                     feature.type !== "annotation" && 
                                                     feature.type !== "annotationEnd");
    },

    propertiesWindowSelectedValue() {
      if (this.selectedLayer) {
        return this.selectedLayer;         
      } else {
        return this.selectedFeatures.length === 1 ? this.selectedFeatures[0] : null
      }      
    },

    userFeatures() {
      return this.userFeaturesLayer ? this.userFeaturesLayer.features : []
    },
 
    transformableFeatures() {
      return this.allFeatures.filter(feature => feature.transform);
    },         

    copyrights() {
      let basemapCopyrights = this.basemaps.filter((basemap) => basemap.visible===true && basemap.copyright!=="")
                                    .reduce((unique,item)=>unique.includes(item.copyright)?unique:[...unique,item.copyright],[]);            

      console.log(this.user.roles);

      let licenseNo = this.user.roles.includes('GYBC') ? 'AC0000810977' : 'AC0000851272';

      let ncc = "© Norfolk County Council currentyear";
      let ordinance = `© Crown Copyright and Database rights currentyear Ordnance Survey ${licenseNo}`
      let esri = "Powered by Esri"

      let date = new Date().getFullYear();
      
      let copyrights = ([ncc, ordinance, ...basemapCopyrights, esri]);      
      return copyrights.map(copyright => {
        return copyright.replace(/currentyear/g, date) 
      })
    },

    locatorLayers() {
      return this.searchItems.filter(item => item.type === "locator").map(item => item.subLayer);
    },

    osLayers() {
      return this.searchItems.filter(item => item.type === "OS").map(item => item.subLayer);
    },
    
    allLayers() {
      return [...this.layers, ...this.allGroupLayers, ...this.allSublayers];
    },    

    selectedSearchSubLayer() {
      return this.selectedSearchItem ? this.selectedSearchItem.subLayer : null
    },

    searchItems() {                       
      return [...this.osSearches, /*...this.locatorSearches, locatordisabled*/...this.subLayerSearches];
    },

    userFeaturesLayerItem() {

      let item = null;

      if (this.userFeaturesLayer) {
        
        let fields = this.userFeaturesLayer.fields.filter(field => field.name !== this.userFeaturesLayer.objectIdPropertyName);

        fields = fields.map(field => {
          return {
            name: field.name,
            alias: field.alias,
            type: field.type,
            filterText: field.identifyResultsFilterText
          }
        })
  
        item = { id: "createdFeatures",
                layer: this.userFeaturesLayer,
                title: this.userFeaturesLayer.title, 
                fields: fields,
                sortedField: this.userFeaturesLayer.identifyResultsSortedField,
                sortedAscending: this.userFeaturesLayer.identifyResultsSortedAscending,
                features: this.userFeaturesLayer.features.filter(feature => feature.isInSelectionRange)}
      }

      return item;
    },

    searchLayerItem() {
      let searchItem = null;

      if (this.selectedSearchSubLayer) {
        
        let fields = [];
        
        if (this.selectedSearchSubLayer.fields) {
          fields = this.selectedSearchSubLayer.fields.map(field => {
            return {
              name: field.name,
              alias: field.alias,
              type: field.type,
              filterText: field.identifyResultsSearchFilterText
            }
          })
        }

        searchItem = { id: "search",
                      layer: this.selectedSearchSubLayer,
                      title: this.selectedSearchItem.title + " (Search results)", 
                      fields: fields,
                      sortedField: this.selectedSearchSubLayer.identifyResultsSearchSortedField,
                      sortedAscending: this.selectedSearchSubLayer.identifyResultsSearchSortedAscending,
                      features: this.selectedSearchSubLayer.features.filter(feature => feature.isInSelectionRange && feature.isSearchResult) }
      }

      return searchItem;
    },

    identifyLayerItem() {
      let item = this.identifyLayerItems.find(item => item.id === this.identifyLayerItemId);
      return item ? item : null;
    },

    selectedSearchItem() {
      let item = this.searchItems.find(item => item.id === this.selectedSearchItemId);
      return item ? item: null
    },

    identifyLayerItems() {

      let subLayerItems = this.visibleActiveSubLayers.map(subLayer => {

        let fields = [];

        if (subLayer.fields) {
          fields = subLayer.fields.map(field => {
            return {
              name: field.name,
              alias: field.alias,
              type: field.type,
              filterText: field.identifyResultsFilterText
            }
          })
        }
        
        let title = subLayer.title
        if (subLayer.parent === this.privateLayersGroup) {
          title += " (Private layer)";
        }

        return { id: subLayer.globalId,
                 layer: subLayer,
                 title: title, 
                 fields: fields,
                 sortedField: fields.find(field => field.name === subLayer.identifyResultsSortedField.name),
                 sortedAscending: subLayer.identifyResultsSortedAscending,
                 features: subLayer.features.filter(feature => feature.isInSelectionRange) }
      })
      
      subLayerItems = subLayerItems.sort((itemA, itemB) => {
        return itemA.title.localeCompare(itemB.title);
      });  

      let items = null;

      if (this.searchLayerItem) {        
        items =  [this.searchLayerItem, ...subLayerItems];
      } else {
        items = subLayerItems;
      }

      if (this.userFeaturesLayerItem) {
        items = [this.userFeaturesLayerItem, ...items]
      }

      return items;
    },
    
    legendSubLayers() {
      let subLayers = [...this.visibleActiveSubLayers];
      if ((this.selectedSearchSubLayer && 
          (subLayers.indexOf(this.selectedSearchSubLayer) === -1) &&
          (this.searchResults.length))) 
      {
        subLayers.push(this.selectedSearchSubLayer);
      }
      
      return subLayers;
    },

    searchResults() {      
      return this.allFeatures.filter(feature => feature.isSearchResult);
    },   

    layersForLayerManager() {
      return this.layers.filter(layer => { 
        if ((layer.id === "privateLayers") && (this.canCreateLayers)) {
          return true;
        }
        else {
          return layer.allSublayers.find(subLayer => subLayer.includeInLayerManager === true);      
        }
      })
    },

    itemsForPropertyWindow() {

      let items = [];

      if (this.selectedLayer) {
        
        items.push({
          type: "layer",
          propertiesType: "layer",
          text: this.selectedLayer.title,
          value: this.selectedLayer
        })        
      } else {      

        let features = this.clickedFeatures.slice();

        this.selectedFeatures.forEach(feature => {
          if (features.indexOf(feature) === -1) {
            features.push(feature);
          }
        })

        features = features.filter(feature => {
          if (feature.isSearchResult) {
            return true;
          }             

          if (feature.type === 'annotationEnd' || feature.type === "measurement") {
            return true;
          }
          
          if ((feature.layer === this.userFeaturesLayer) && 
              (this.userFeatures.indexOf(feature) !== -1)) {
            return true;
          }        

          if (this.isVisibleActive(feature.layer)) {
            return true;
          }
        })
          
        items = features.map(feature => {        

          let itemText = "";
          let itemType = "";

          if (feature.layer) {

            itemType = "feature";

            if (feature.isSearchResult) {
              itemText += "(Search) "
            }

            itemText += feature.layer.title + " / "                    

            if (feature.layer.namePropertyName) {
              let value = feature.attributes[feature.layer.namePropertyName];
              if (value) {
                itemText += value;
              } else {
                let geometryType = this.getGeometryType(feature);
                if (geometryType === "polyline") {
                  geometryType = "line"
                }
                itemText += geometryType.charAt(0).toUpperCase() + geometryType.slice(1);
              }
            } else {
              itemText += feature.layer.fields.find(field => field.name === feature.layer.objectIdPropertyName).alias + ": " + 
                          feature.attributes[feature.layer.objectIdPropertyName]
            }                          
          } else if (feature.line) {
            itemType = "label"
            itemText = "Label"                      
          } else if (feature.type === "annotationEnd") {
            itemType = "label"
            itemText = "Label"          
          } else if (feature.type === "measurement") { 
            itemType = "measurement"
            itemText = "Measurement"
          }

          return {
            propertiesType: "feature",
            type: itemType,
            text: itemText,
            value: feature                
          }        
        })
      }
      
      return items;
    },

    overviewBasemaps() {
      return this.basemaps.filter(basemap => basemap.overviewEnabled)
    },    

    featuresInSelectionRange() {      
      return this.allFeatures.filter(feature => feature.isInSelectionRange);
    },

    selectedFeatures() {                      
      return this.allFeatures.filter(feature => feature.isSelected);
    },

    selectedFeature() {
      return this.selectedFeatures.length === 1 ? this.selectedFeatures[0] : null
    },

    allFeatures() {
      let features = []

      this.allSublayers.filter(subLayer => subLayer.features).forEach(layer => {        
        features = features.concat(layer.features);        
      })
      
      return [...features, ...this.measurements, ...this.annotationLines, ...this.annotationEnds];      
    },    
    
    allGroupLayers()
    { 
      // Ordered from back to front.      

      let groupLayers = [];      
      this.layers.forEach(layer => {                
        groupLayers = groupLayers.concat(this.recurseGroupLayer(layer));        
      })      
      
      return groupLayers;
    },           
    allSublayers()
    { 
      // Ordered from back to front.      

      let subLayers = [];      
      this.layers.forEach(layer => {                
        subLayers = subLayers.concat(this.recurseSubLayer(layer));        
      })      
      
      return [...subLayers, ...this.osLayers, ...this.locatorLayers];
    },       
    
    visibleBookmarks() {
      return this.bookmarks.filter(bookmark => { return bookmark.visible });      
    },    

    componentsContainerPaddingOffsets()
    {
      return {            
        paddingLeft: this.componentsLeft + "px",
        paddingTop: this.componentsTop + "px",           
        paddingRight: this.componentsRight + "px",
        paddingBottom: this.componentsBottom + "px"
      }
    },      
  },
  watch: {                    

    menuItems: {      
      handler(value) {
        let isOpen = value["results"];        
        
        if (this.identifyFeature) {
          this.identifyFeature.agsFeature.visible = isOpen;        
          if (this.identifyFeature.bufferGraphic) {
            this.identifyFeature.bufferGraphic.visible = isOpen;
          }
        } 
      },
      deep: true
    },

    featuresLabelSymbol: {
      handler(newValue) {
        this.showFeatures();              
      }, deep: true          
    },

    allSystemLayerFields: {
      handler(newValue) {
        this.showFeatures();   
      }, deep: true    
    },        

    identifyLayerItemId() {
      if (!this.$appGlobals.isRestoringState) {    
        this.identifyInSelection();        
      }
    },
    locationVisibleOnMap() {
      this.showFeatures(); 
    },          
    lengthUnit() {
      this.showFeatures(); 
    },
    areaUnit() {
      this.showFeatures(); 
    },    
    lengthVisibleOnMap() {    
      this.showFeatures(); 
    },    
    sideLengthsVisibleOnMap() {    
      this.showFeatures(); 
    },    
    areaVisibleOnMap() {
      this.showFeatures(); 
    },     

    visibleActiveSubLayers(newVisibleLayers, oldVisibleLayers) {      
      
      let hiddenLayers = oldVisibleLayers.diff(newVisibleLayers);      

      hiddenLayers.forEach(subLayer => {

        let features = subLayer.features.filter(feature => !feature.isSearchResult);

        for (let feature of features) {      
          feature.isSelected = false;                        
          feature.transform = false;                       
        }         

        if (this.identifyLayerItemId === subLayer.globalId) {
          this.identifyLayerItemId = null;
        }
      })          
    },

    componentsLeft(offset) {
      if (this.mapView.padding) {
        this.updateViewPadding();
      }
    },
    componentsTop(offset) {
      if (this.mapView.padding) {
        this.updateViewPadding();
      }
    },
    componentsRight(offset) {
      if (this.mapView.padding) {
        this.updateViewPadding();
      }    
    },
    componentsBottom(offset) {
      if (this.mapView.padding) {
        this.updateViewPadding();
      }    
    },

    basemaps: {
      handler(newValue) {
        
        newValue.forEach(basemap => {
          let agsBasemap = this.map.basemap.baseLayers.items.find(agsB => agsB.id === basemap.id);               

          if (agsBasemap) {
            agsBasemap.opacity = basemap.opacity;

            let extendedAgsBasemap = this.map.basemap.baseLayers.items.find(agsB => agsB.id === basemap.id + "_extended");               

            if (this.useExtendedScales && extendedAgsBasemap) {          
              extendedAgsBasemap.opacity = basemap.opacity;              
              
            if (this.mapView.scale < 500) {
                extendedAgsBasemap.visible = basemap.visible;                
                agsBasemap.visible = false;
              } else
              {
                agsBasemap.visible = basemap.visible;                
                extendedAgsBasemap.visible = false;
              }
          } else {             
              agsBasemap.visible = basemap.visible;          
            }
          }
        })
      },
      deep: true
    },

    searchResults(searchResults) {      
      this.showSearchFeatures();
    },    
    
    selectedSystemFeatures() {
      this.showFeatures(); 
    },    

    visibleBookmarks(){
      this.updateBookmarksOnMap();      
    },
  },
  methods: {

    canDeleteDrawnItems() {
      let items = this.activeDeleteAll === 'all' ? this.allDrawnItems : this.drawnItemsInView;
      return (items.length > 0 && this.selectedFeatures.every(item => this.canDeleteFeature(item)));
    },

    async layerInfo(layer) {      

      this.addEvent("layerInfo", this.getLayerPath(layer));       
            
      let path = this.getLayerPath(layer, false, false);
      this.isLoadingMeta = true;
      try {
        layer.meta = await this.mbApi.getLayerMeta(layer.rootLayer.arcGISPath, path);
      } finally {
        this.isLoadingMeta = false;
      }                  

      this.clearSelectedFeatures();
      layer.isSelected = true;
      this.$emit("openMenuItem", "properties"); 
    },

    getSubLayerSearches()
    {
      return this.searches.filter(search => !search.locatorUrl).map(search => {
        let layer = this.layers.find(layer => layer.id === search.layerId);
        let subLayer = layer ? layer.allSublayers.find(subLayer => subLayer.id === search.subLayerId) : null;
        let item = Object.assign({}, search, { type: "arcGIS", subLayer: subLayer });
        return item;
      })
    },    

    gotoFeatures(features) {
      let extent = this.getExtentForFeatures(features);      
      this.goto(extent.center)            
    },

    gotoFeaturesAndZoomToFit(features) {
      let extent = this.getExtentForFeatures(features);      
      this.goto({extent: extent})            
    },    

    deleteAll(type) {
      if (this.canDeleteDrawnItems) {
        let items = type === 'all' ? this.allDrawnItems : this.drawnItemsInView;
        this.deleteFeatures(items);
      }      
    },
  
    selectAll(type) {
      
      console.log("this.clearSelectedFeatures();")
      this.clearSelectedFeatures();
      
      let labels = this.annotations.map(feature => feature.end);
      let features = [...this.userFeatures, ...this.measurements, ...labels];       
      if (type === "inView") {
        features = features.filter(feature => this.mapView.extent.contains(feature.geometry.extent ? feature.geometry.extent : feature.geometry));
      } 

      features.forEach(feature => {        
        feature.isSelected = true;
        if (this.isFeatureTransformable(feature))          
          feature.transform = true;
      })             
      
      this.addNavItem(features);
    },

    renderFeature(feature) {      

      if (feature.type === "userFeature") {    
        this.userFeaturesLayer.features.push(feature)    
        feature.layer = this.userFeaturesLayer;        
        this.userFeaturesGfxLayer.graphics.add(feature.agsFeature);      
        this.agsFeatureToVueFeature.set(feature.agsFeature, feature);   
      }

      if (feature.type === "measurement") {    
        this.measurements.push(feature);
        this.measurementsGfxLayer.graphics.add(feature.agsFeature);    
        this.agsFeatureToVueFeature.set(feature.agsFeature, feature);   
      }

      if (feature.type === "annotation") {
        this.annotations.push(feature);        
        this.annotationEndsGfxLayer.graphics.add(feature.end.agsFeature); 
        this.annotationLabelsGfxLayer.graphics.add(feature.label.agsFeature); 
        this.agsFeatureToVueFeature.set(feature.label.agsFeature, feature.label);
        this.agsFeatureToVueFeature.set(feature.end.agsFeature, feature.end); 

        if (feature.line) {
          this.annotationLinesGfxLayer.graphics.add(feature.line.agsFeature);                  
        }
      }                               
    },    

    async undoDelete() {            
      console.log(this.deletionHistory.length);
      let toRestore = this.deletionHistory.pop();
      console.log(this.deletionHistory.length);
      this.clearSelectedFeatures();
      console.log(toRestore);
      toRestore.forEach(restorable => {
        this.renderFeature(restorable);
        if (restorable.type === "annotation") {
          restorable.end.isSelected = true;
          restorable.end.transform = true;
        } else {
          restorable.isSelected = true;
          restorable.transform = true;          
        }
        
        if (restorable.type === "userFeature" && this.selectionGeometry) {
          restorable.isInSelectionRange = this.geometryContainsOrIntersects(this.selectionGeometry, restorable.geometry);
        }                
      })      
      
      let features = toRestore.map(restorable => restorable.type === "annotation" ? restorable.end : restorable);      
      this.addNavItem(features);      

      let extent = this.getExtentForFeatures(features);

      if (this.mapView.extent.contains(extent)) {
        return;
      }

      await this.goto(extent.center);

      if (!this.mapView.extent.contains(extent)) { 
        this.goto(extent);
      }                  
    },       

    mapPointerDown(e) {
      this.lastPointerDownMapPoint = this.mapView.toMap(e);               
      this.lastPointerDownMapCenter = this.mapView.center;
      this.lastPointerDown = e;                         
      if (this.lastPointerDown.native.pointerType === 'touch') {                                                
        this.touchDownCount += 1;

        if (this.touchDownCount === 1) {
          this.processClick = true;
            this.contextMenuTimeOut = setTimeout(() => {                   
              this.processClick = false;
              this.showContextMenu();
          }, 750)
        } else if (this.touchDownCount > 1) {
          clearTimeout(this.contextMenuTimeOut);
          this.contextMenuTimeOut = null;
          this.contextMenuPopoverVisible = false;
          this.processClick = false;        
        }                                
      } else {
        this.processClick = true;
      }                            
    },

    mapPointerUp(e) {
            
      // hide context menu

      if (e.native.pointerType === 'touch') {
        
        this.touchDownCount -= 1;                
        
        if (this.contextMenuTimeOut) {                  
          clearTimeout(this.contextMenuTimeOut);
          this.contextMenuTimeOut = null;
        }                
      }                             
      
      this.$nextTick(() => {      // Let sketchViewModel(update) process first.
        if (this.processClick) {
            // Cant just simply subscribe to mapView.OnClick as SketchViewModel swallows (doesnt fire) the click when reshaping.                                
          this.click(e);                  
          this.processClick = false;
        }
      })                                              
    },

    globalMouseUp(e) {
      if(e.button === 3) // back
      {                                    
        if (this.previousEnabled) {
          this.previousItem();
        }        
      }                

      if(e.button === 4) // forward
      {                                    
        if (this.nextEnabled) {
          this.nextItem();
        }        
      }                    
    },

    click(e) {

      if (e.button === 0) { // left
        if (this.activePointerTool === 'defaultTool') {                              
          if (this.lastPointerDownMapCenter.equals(this.mapView.center)) {
            this.defaultToolClick(e);                      
          }
        }                                   
      }            

      if(e.button === 2) //right 
      {                                    
        this.showContextMenu();        
      }                            
    },

    async zoom(out, target) {            
      if (this.mapView.animation && 
          this.mapView.animation.target &&          
          this.mapView.animation.target.scale &&        
          this.mapView.animation.target.scale !== this.mapView.scale) {
        // Already in the process of zooming
        return;
      }          
      
      let newScale;
      if (out) {
        let scales = this.availableScales.filter(scale => scale > this.mapView.scale);        
        if (scales.length > 0) {
          newScale = scales[0];        
        }        
      } else {
        let scales = this.availableScales.filter(scale => scale < this.mapView.scale);              
        if (scales.length > 0) {
          newScale = scales[scales.length - 1];        
        }                
      }

      if (newScale) {        
        this.goto({scale: newScale, center: target});                      
      }            
    },    
   
    async deleteConfirmed(layer) {      
      try {
        await this.mbApi.deleteLayer(layer);           
      } catch (e) {
        console.error(e);
        this.errorTitle = "Could not delete layer."
        this.errorMessage = e.message;
        this.$refs.ErrorDialog.show();                    
        throw e;
      }

      await this.setLayerVisible(layer, false);                        

      let index = this.privateLayersGroup.sublayers.indexOf(layer);
      this.privateLayersGroup.sublayers.splice(index, 1);

      index = this.privateLayersGroup.allSublayers.indexOf(layer);
      this.privateLayersGroup.allSublayers.splice(index, 1);
        
      this.map.layers.remove(layer.agsLayer);
      await this.$parent.SyncProject(true);      
    },
    
    async deleteLayer(layer) {

      this.confirmationDialogMessage = "Are you sure you want to delete " + '"' + layer.title + '"' + " ?";
      this.confirmationDialogData = layer;
      this.$bvModal.show("deleteLayerConfirmationDialog");
    },

    layerEditValidation(layer, layerName) {
      if (!layerName) {
        return "";
      }
         
      let layers = this.privateLayersGroup.allSublayers.filter(existingLayer => existingLayer !== layer);
      if (layers.find(privateLayer => privateLayer.title === layerName)) {
        return `Private layer with name "${layerName}" already exists`;                 
      }              

      // indicates valid
      return null;
    },

    async layerEdited(args) {
      let layer = args.layer;      

      let symbol = new this.agsSimpleMarkerSymbol({
        style: args.symbolStyle,
        color: args.symbolColor,
        size: args.symbolSize
      });      

      layer.symbol = symbol;
      layer.title = args.layerName;

      if (!this.privateLayersGroup.allSublayers.find(aLayer => aLayer === layer)) {
        // Just created.

        this.defineLayerProperties(layer);   

        layer.features.forEach(feature => {          
          feature.geometryJSON = feature.geometry.toJSON();
          feature.geometry = null;
        })

        try {
          await this.mbApi.createLayer(layer);    
        } catch (e) {
          console.error(e);
          this.errorTitle = "Could not create layer."
          this.errorMessage = e.message;
          this.$refs.ErrorDialog.show();                    
          throw e;
        }

        this.$set(layer, "parent", this.privateLayersGroup);
        this.$set(layer, "rootLayer", this.privateLayersGroup);        
        this.privateLayersGroup.sublayers.push(layer);
        this.privateLayersGroup.allSublayers.push(layer);                       
        
        layer.expandedInAllTree = true;  
        await this.setLayerVisible(layer, true);       
        await this.zoomToFitFeatures(layer.features);
        
        await this.$parent.SyncProject(true);      
      } 
      else      
      {
        layer.features.forEach(feature => {
          feature.symbol = layer.symbol;            
        })        

        layer.agsLayer.graphics.items.forEach(graphic => {
          graphic.symbol = layer.symbol;
        })

        this.showSelectedFeatures();
      }
    },

    async loadFeatures(layer) {      
      let features = [];
      try {
        features = await this.mbApi.getFeatures(layer.id);      
      }
      catch(e) {
        this.errorTitle = "Could not load private layer.  Server maybe unavailable"
        this.errorMessage = layer.title;
        this.$refs.ErrorDialog.show();
        throw e;
      }
      
      layer.features = features;

      layer.features.forEach(feature => {
        feature.geometry = this.agsPoint.fromJSON(feature.geometryJSON);
        
        feature.geometryJSON = "";
        feature.layer = layer;
      })        
      
      let agsIndex = this.map.layers.indexOf(this.searchFeaturesGfxLayer);                  
              
      let gfxLayer = new this.agsGraphicsLayer({
        id: "privateGfxLayer_" + layer.id,
        title: layer.id,            
        visible: false
      });   
      
      layer.features.forEach(feature => {
        feature.symbol = layer.symbol;

        let symbolGraphic = new this.agsGraphic({                
          geometry: feature.geometry,
          symbol: feature.symbol,              
        });            
                 
        gfxLayer.add(symbolGraphic);                  
      })

      this.map.layers.add(gfxLayer, agsIndex);                      
      layer.agsLayer = gfxLayer;              
    },

    importIntoLayer(layer) {      

      var input = document.createElement('input');
      input.setAttribute("accept", ".xls, .xlsx");

      input.onchange = async e => {     

        let file = e.target.files[0];

        try {
          await this.$refs.ImportLayerDialog.validate(file);
        }
        catch (e) {
          this.errorTitle = "Invalid import file"
          this.errorMessage = e.message;
          this.$refs.ErrorDialog.show();                    
          throw e;
        } 

        this.layerImportFile = file;
        this.$refs.ImportLayerDialog.show();                                    
      }
            
      input.type = 'file';
      input.click();      
    },
    
    async layerImportDialogOk(args) {      
      if (args.error) {
        this.errorTitle = "Invalid import file"
        this.errorMessage = args.error.message;
        this.$refs.ErrorDialog.show();                    
      } else {
        let layer = args.layer;      
        layer.symbol = new this.agsSimpleMarkerSymbol(this.defaultLayerEditSymbol);            
        this.editLayer = Vue.observable(layer);

        this.$refs.EditLayerDialog.show();                   
      }
    },
    
    doEditLayer(layer) {
      this.editLayer = layer;
      this.$refs.EditLayerDialog.show();       
    },

    gotoLocation() {      
      this.editingCoords = true;
    },

    contextMenuClearSelection() {      
      this.clearSelectedFeatures();
    },    

    googleMaps(){            
      this.addEvent('openGoogleMaps');                

      const gridref = new OsGridRef(this.lastPointerDownMapPoint.x, this.lastPointerDownMapPoint.y);
      const pWgs84 = gridref.toLatLon();
      window.open(`https://www.google.com/maps?ie=UTF8&cbll=${pWgs84._lat},${pWgs84._lon}&cbp=1,0,,0,5&ll=${pWgs84._lat},${pWgs84._lon}`, '_blank');                        
    },

    streetView(){            
      this.addEvent('openGoogleStreetView');          
      

      const gridref = new OsGridRef(this.lastPointerDownMapPoint.x, this.lastPointerDownMapPoint.y);
      const pWgs84 = gridref.toLatLon();
      window.open(`https://www.google.com/maps?ie=UTF8&layer=c&cbll=${pWgs84._lat},${pWgs84._lon}&cbp=1,0,,0,5&ll=${pWgs84._lat},${pWgs84._lon}`, '_blank');                        
    },

    bingMaps(){            
      this.addEvent('openBingMaps');                      

      const gridref = new OsGridRef(this.lastPointerDownMapPoint.x, this.lastPointerDownMapPoint.y);
      const latlon = gridref.toLatLon();
      let link = "https://bing.com/maps/default.aspx?cp=" + latlon._lat + "~" + latlon._lon + "&lvl=20&style=h";
      window.open(link, '_blank');              
    },

    showContextMenu() {      

      this.showDeleteLocationPinMenuOption = false;

      if (this.locationPin) {                   
        this.showDeleteLocationPinMenuOption = true;                    
      }

      this.showIdentifyInSelectionMenuOption = false; 
      this.showMapSheetOption = false; 
      
      if ((this.selectedFeature) && (this.selectedFeature.type !== "annotationEnd")) {                                              

        let clickExtent = this.getClickExtent(this.lastPointerDownMapPoint, this.lastPointerDown.native.pointerType);

        if (clickExtent.contains(this.selectedFeature.geometry) || clickExtent.intersects(this.selectedFeature.geometry))
        {
          this.showIdentifyInSelectionMenuOption = true;                                            
        }        
      }

      if (this.getMapSheetReference(this.lastPointerDownMapPoint)) {
        this.showMapSheetOption = true; 
      }                              
      
      this.contextMenuPopoverVisible = true;

      this.$nextTick(() => {
        this.contextMenuCoords = this.mapView.toScreen(this.lastPointerDownMapPoint);
      })
    },

    onMapScaleChanged() {      

      if (this.useExtendedScales) {
                    
        let visibleVueBaseMaps = this.basemaps.filter(basemap => basemap.visible);
        visibleVueBaseMaps.forEach(vueBasemap => {

          let agsBasemap = this.map.basemap.baseLayers.items.find(basemap => vueBasemap.id == basemap.id);
          if (vueBasemap.extendedScalesUrl) {          

            let extendedAgsBasemap = this.map.basemap.baseLayers.items.find(basemap => basemap.id == vueBasemap.id + "_extended");
            agsBasemap.visible = this.mapView.scale >= 500;
            extendedAgsBasemap.visible = this.mapView.scale < 500;
          }       
        })            
      }

      this.exportPresentedForceUpdate = !this.exportPresentedForceUpdate;
    },

    addEvent(type, details) {
      this.mbApi.addEvent(type, details);
    },

    downloadProject(project) {
      let projectJSON = JSON.stringify(project);                     
      this.addEvent("exportMBP");      
      this.download(projectJSON, "MappingBrowser.mbp", "text/csv;charset=utf-8;")
    },

    gotoBookmark(args) {
      if (!this.$appGlobals.isRestoringState) {    
        this.addEvent("bookmarkUsed");        
      }
      
      this.goto(args);
    },    

    defaultAll() {
      this.contextMenuPopoverVisible = false;
      this.clearSelectedFeatures();
      this.editingCoords = false;
      this.activePointerTool = "defaultTool";
    },

    globalKeyDown(e) {      
      
      if (e.key === "Ctrl") {
        this.ctrlkeyDown = true;
      }      

      if (e.target.nodeName !== "TEXTAREA" &&
          e.target.nodeName !== "INPUT" &&
          e.key === "Shift" && 
          !e.repeat) {        
        this.activePointerTool = "zoomTo";
      }
    },    

    globalKeyUp(e) {               

      if (e.key === "Ctrl") {
        this.ctrlkeyDown = false;
      }      

      if (e.key === "Shift" && 
         !e.repeat && 
         this.activePointerTool === "zoomTo") {               
        this.activePointerTool = "defaultTool";
      }            

      if (e.key === "Escape") {        
        this.defaultAll();
      }    

      if (e.key === "Delete" &&
          e.target.nodeName !== "TEXTAREA" &&
          e.target.nodeName !== "INPUT" &&        
          !e.repeat) {        

        if (this.selectedFeatures.length > 0 && this.selectedFeatures.every(feature => this.canDeleteFeature(feature))) {
          this.deleteFeatures(this.selectedFeatures.slice())
        }        
      }    
    },

    mapKeyUp(e) {
      console.log("mapKeyUp");    
    },

    deleteNavItemFeature(feature) {
      for (let i = this.navHistory.length - 1; i >= 0; i--) {
        let navItem = this.navHistory[i];
        navItem = navItem.filter(navFeature => feature !== navFeature);

        if (navItem.length > 0) {
          this.navHistory[i] = navItem;                                   
        } else {            
          this.navHistory.splice(i, 1);

          if (this.navIndex >= i) {
            this.navIndex -= 1;
          }      
        }
      }     

      this.navIndex = this.removeAdjacentDupsAndEmpties(this.navHistory, this.navIndex)         
    },

    removeAdjacentDupsAndEmpties(navHistory, navIndex) {        
      for (let i = navHistory.length - 1; i >= 0; i--) {
        let navItem = navHistory[i];                            
        if (navItem.length === 0) {
          // remove nav item.
          navHistory.splice(i, 1);                            
                  
          if (navIndex >= i) {
            navIndex -= 1;
          }    
        }
        else if (i > 0) {                    
          let prevNavItem = navHistory[i - 1];            
          if (prevNavItem.equals(navItem)) {
            navHistory.splice(i, 1);                            
                    
            if (navIndex >= i) {
              navIndex -= 1;
            }    
          }          
        }
      }      

      return navIndex;        
    },

    addNavItem(item) {      

      console.log("navItem " + item.map(item => item.type));

      // Limit.
      if (this.navHistory.length === 20) {
        this.navHistory.splice(0, 1);               
        this.navIndex -= 1;
      }

      if (this.navIndex > - 1) {
        let current = this.navHistory[this.navIndex];
        if (current.equals(item)) {
          return;
        }
      }
            
      this.navHistory.splice(this.navIndex + 1, this.navHistory.length - this.navIndex, item);       
      this.navIndex = this.navHistory.length - 1;      
    },
    
    previousItem() {

      console.log("previousItem")
      console.log(this.navIndex);
      console.log(this.navHistory)

      if (this.selectedFeatures.length > 0) {        
        this.navIndex -= 1;
      }       
      
      let features = this.navHistory[this.navIndex];
      this.navFeatures(features);
    },
    
    nextItem() {   

      this.navIndex += 1;      

      let features = this.navHistory[this.navIndex];
      this.navFeatures(features);
    },

    async navFeatures(features) {      
      this.clearSelectedFeatures();      
      features.forEach(feature => {
        feature.isSelected = true;
        if (this.isFeatureTransformable(feature)) {                
          feature.transform = true;        
        }      
      })     

      this.$emit("openMenuItem", "properties"); 

      let extent = this.getExtentForFeatures(features);

      if ((this.mapView.extent.contains(extent)) || (this.mapView.extent.intersects(extent))) {
        return;
      }
      
      await this.goto(extent.center);

      if (!this.mapView.extent.contains(extent)) { 
        this.goto(extent);
      }                  
    },

    getMapSheetReference(point) {
      let gridSquare = "";

      switch (point.x.toString().substr(0, 1) + point.y.toString().substr(0, 1)) {
          case "63":
                  gridSquare = 'TG';
                  break;
          case "53":
                  gridSquare = 'TF';
                  break;
          case "52":
                  gridSquare = 'TL';
                  break;
          case "62":
                  gridSquare = 'TM';
                  break;
          default:
                  break;
      }
      var northSouth = "S";
      if (parseInt(point.y.toString().substr(2, 1)) > 4) northSouth = "N";
                    
      var eastWest = "W";
      if (parseInt(point.x.toString().substr(2, 1)) > 4) eastWest = "E";

      gridSquare += point.x.toString().substr(1, 1) + point.y.toString().substr(1, 1) + northSouth + eastWest;
      return gridSquare;
     },

     exportPresentedMap() {       
       this.$refs.exportPresentedDialog.show();        
     },

     async exportPresented(args) {        

      this.addEvent("exportPresented", args.template + ", " + args.layout  + ", " +  args.format);

      this.exportTemplate = args.template;
      this.exportLayout = args.layout;
      this.exportFormat = args.format;
      this.exportTitle = args.title;            

      // print template

      let layout = args.layout;

      if (this.exportTemplate === "GYBC") {
        layout += "_GYBC"
      }

      let agsTemplate = new this.agsPrintTemplate({
        format: this.exportFormat,
        exportOptions: {
          dpi: 300
        },
        layout: layout,
        layoutOptions: {
          titleText: this.exportTitle,
          authorText: ""
        }
      });

      var params = new this.agsPrintParameters({
        view: this.mapView,
        template: agsTemplate
      });        

      let url = this.arcGISBaseUrl + "/server/rest/services/CustomUtilities/ExportWebMapTest/GPServer/Export%20Web%20Map";

      this.$refs.WaitDialog.show();   

      let result;
      try {
        result = await this.agsPrint.execute(url, params);
        this.$refs.WaitDialog.hide();      

        let a = document.createElement("a"); //Create <a>
        a.href = result.url;
      
        a.target="_blank";
        a.click(); 

      } catch (e) {
        this.$refs.WaitDialog.hide();      
        console.error(e);
        this.errorMessage = url;        
        this.errorTitle = "Could not export.  Server maybe unavailable"          
        this.$refs.ErrorDialog.show();     
      }       
    },

    refreshIdentifyFeature() {
      if (this.identifyFeature) {

        this.identifyFeature.agsFeature.symbol = this.getIdentifySymbol(this.identifyFeature.agsFeature.geometry.type);
        this.identifyFeature.agsFeature.visible = this.menuItems["results"];

        if (this.identifyFeature.bufferGraphic)  {
          this.identifyGfxLayer.remove(this.identifyFeature.bufferGraphic);                         
        }

        this.identifyFeature.bufferGraphic = null;

        let bufferGraphic = this.getIdentifyBufferGraphic(this.identifyFeature.agsFeature.geometry);
        if (bufferGraphic) {                    
          this.identifyFeature.bufferGraphic = bufferGraphic;
          this.identifyFeature.bufferGraphic.visible = this.menuItems["results"];
          this.identifyGfxLayer.add(this.identifyFeature.bufferGraphic);                 
        }            
      }      
    },

    identifyBufferChanged(bufferSize, bufferUnit) {
      this.identifyBufferSize = bufferSize;
      this.identifyBufferUnit = bufferUnit;

      if (this.identifyFeature) {

        this.refreshIdentifyFeature();
        this.identifySelectionGeometryChanged();
      }      
    },

    deleteLocationPin() {
      this.mapPinGfxLayer.graphics.remove(this.locationPin);              
      this.locationPin = null;      
    },

    pinLocation(location) {                     

      if (this.locationPin) 
      {
        this.mapPinGfxLayer.graphics.remove(this.locationPin);   
        this.locationPin = null;     
      }      

      if (location) {

        if (!this.$appGlobals.isRestoringState) {         
          this.addEvent("setLocationPin");          
        }

        this.locationPin = new this.agsGraphic({
          attributes: {              
            mappingBrowserType: "locationPin"
          },
          
          geometry: location,
          symbol: this.mapPinSymbol
        });

        this.mapPinGfxLayer.graphics.add(this.locationPin);              
      }
    },

   async downloadScreenshot() {

      this.addEvent("exportScreenshot");      

      let options = {
        ignorePadding: false
      };

      let screenshot = await this.mapView.takeScreenshot(options);

      const imageData = screenshot.data;

      // to add the text to the screenshot we create a new canvas element
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      canvas.height = imageData.height;
      canvas.width = imageData.width;

      // add the screenshot data to the canvas
      context.putImageData(imageData, 0, 0);
      context.font = "12px Arial";
      context.fillStyle = "#000";            

      let textHeight = 24;   
      let offset = 10;              
      
      let copyrightsHeight = (this.copyrights.length * 24);
      let top = imageData.height - copyrightsHeight;

      let widths = this.copyrights.map(copyright => {
        let width = context.measureText(copyright).width;
        return width;
      });

      let maxWidth = Math.max(...widths);

      let rectTop  = top - offset;
      context.fillRect(0, rectTop, maxWidth + 20, rectTop + copyrightsHeight);

      context.fillStyle = "#fff";            
      for (let i = 0;  i < this.copyrights.length; i++) {        
        let copyright = this.copyrights[i];
        context.fillText(copyright, 12, top + offset + (i * textHeight));          
      }

      /* // fillRect(x, y, width, height);
      // fillText(text, x, y [, maxWidth]);

      context.fillRect(0, imageData.height - 40, context.measureText("hello").width + 20, 30);
      // add the text from the textInput element
      context.fillStyle = "#fff";
      context.fillText("hello", 10, imageData.height - 20); */
        
      let a = document.createElement("a"); //Create <a>
      a.href = canvas.toDataURL();            
      a.download = "Mapping Browser map image.png"; //File name Here
      a.click(); //Downloaded file         
    },
    
    getAgsPointByXy(x, y) {
      let coordString = x + "," + y;
      if (isValidCoordString(coordString, "xy")) {
        let mapCoords = coordStringToAgsPoint(this.agsPoint, coordString, this.mapView.spatialReference, "xy");        
        return mapCoords;
      }      
    },    

    showFeatures() {      
      this.showSelectedFeatures();   
      this.showUserFeatureAndMeasurementLabels();   
    },
    
    defineLayerProperties(layer) {
       
      Object.defineProperty(layer, "globalId", {
        get: function globalId() {
          if (this.rootLayer) {
            return this.rootLayer.id + "_MBID_" + this.id;
          }
          else {
            return this.id;
          }                  
        }
      });                                   
    },

    lineAnnotateClicked() {      
      this.activePointerTool='lineAnnotate'      
    },    

    annotateClicked() {      
      this.activePointerTool='annotate'      
    },            

    createFeatureActivated() {            
      this.activePointerTool='createFeature'      
    },

    createFeatureGeometryTypeChanged(geometryType) {            
      this.createFeatureGeometryType = geometryType                
      this.$emit("closeMenuItem", "sketch");
    },    

    measureActivated() {      
      this.activePointerTool='measure'
    },

    measureGeometryTypeChanged(geometryType) {            
      this.measureGeometryType = geometryType                
      this.$emit("closeMenuItem", "measure");
    },    

    getClickTolerance(pointerType) {
      return pointerType === "touch" ? 20 : 14;
    },

    getClickExtent(point, pointerType) {
      let clickExtentSize = this.getClickTolerance(pointerType);
      return this.pointToExtent(this.mapView, point, clickExtentSize);
    },

    defaultToolClick(event) {                                     
      
      let clickPoint = this.mapView.toMap({x: event.x, y: event.y})                    
      let clickExtent = this.getClickExtent(clickPoint, event.native.pointerType);

      let clickedCreatedFeatures = this.userFeatures.filter(feature => {               
        return clickExtent.contains(feature.geometry) || clickExtent.intersects(feature.geometry);
      });              

      let clickedMeasurements = this.measurements.filter(feature => {               
        return clickExtent.contains(feature.geometry) || clickExtent.intersects(feature.geometry);
      });                     

      let clickedAnnotationEnds = this.annotationEnds.filter(feature => {               
        return clickExtent.contains(feature.geometry) || clickExtent.intersects(feature.geometry);
      });                  

      let clickedSearchResults = []
      
      if (this.searchResults.some(result => !this.isVisibleActive(result.layer))) {
        clickedSearchResults = this.searchResults.filter(feature => {                                                 
          return clickExtent.contains(feature.geometry) || clickExtent.intersects(feature.geometry);
        })
      }              

      let clickedPrivateFeatures = this.visiblePrivateFeatures.filter(feature => {                                                 
        return clickExtent.contains(feature.geometry) || clickExtent.intersects(feature.geometry);
      });       
      
      // back to front (then reverved afterwards.)
      let clickedFeatures = [...clickedPrivateFeatures, ...clickedSearchResults, ...clickedCreatedFeatures, ...clickedMeasurements, ...clickedAnnotationEnds].reverse();     
          
      let selectNextFeatureOnLoad = false;      
      let selectedIndex = this.selectedFeature ? this.clickedFeatures.indexOf(this.selectedFeature) : -1;      
                    
      if ((selectedIndex + 1) < clickedFeatures.length) {
        this.clearSelectedFeatures();

        let feature = clickedFeatures[selectedIndex + 1];
        feature.isSelected = true;                   
        if (feature.type === "system") {
          this.addEvent("quickIdentify", this.getLayerPath(feature.layer));       
        }
        this.addNavItem([feature]);

        if (this.isFeatureTransformable(feature)) {              
          feature.transform = true;        
        }

        this.$emit("openMenuItem", "properties");              
      } else {        
        selectNextFeatureOnLoad = true;
        if (this.selectedFeature && (this.selectedFeature.type !== "system")) {
          event.stopPropagation();
        }
      }                                                     
      
      let subLayers = this.visibleActiveSubLayers.slice().filter(layer => layer.rootLayer.type === "arcGIS");
      subLayers = subLayers.filter(subLayer => this.isSubLayerVisibleInZoomRange(subLayer));                                                                                    

      let tolerance = this.getClickTolerance(event.native.pointerType);
      let clickIdentifyFeaturesPromise = this.identifyFeatures(subLayers, clickPoint, tolerance);
      this.latestClickIdentifyFeaturesPromise = clickIdentifyFeaturesPromise
      
      clickIdentifyFeaturesPromise.then(async results => {                                           
        
        let identifiedFeatures = this.mergeIntoFeatures(results);                                   

        let nonLoadedGeometryFeatures = identifiedFeatures.filter(feature => !feature.geometry);
        if (nonLoadedGeometryFeatures.length) { 
          await this.loadGeometryAndSymbolsForFeatures(nonLoadedGeometryFeatures);
        }             

        // Some features do not have geometry (some label features for example, on "Road names" layer)
        identifiedFeatures = identifiedFeatures.filter(feature => feature.geometry);

        // Priortise smaller features.
        let subLayers = [...new Set(identifiedFeatures.map(feature => feature.layer))];
        let sorted = subLayers.map(subLayer => {
          let features = identifiedFeatures.filter(feature => feature.layer === subLayer);
          return features.sort((a, b) => {
            let areaA = this.agsGeometryEngine.planarArea(a.geometry, "square-meters");
            let areaB = this.agsGeometryEngine.planarArea(b.geometry, "square-meters");
            if (areaA > areaB) return 1;
            if (areaA < areaB) return -1;
            return 0;
          }).reverse();
        })         

        identifiedFeatures = sorted.flat();
                
        if (this.latestClickIdentifyFeaturesPromise === clickIdentifyFeaturesPromise) {

          let allIdentifiedFeatures = [...clickedFeatures, ...identifiedFeatures.reverse()]; 

          // remove dups.
          allIdentifiedFeatures = allIdentifiedFeatures.filter(function(item, pos, self) {
              return self.indexOf(item) == pos;
          })                      

          let newPriorityFeatures = [];
          let oldPriorityFeatures = [];

          if (selectNextFeatureOnLoad) {                                           

            if (this.selectedFeature) {
              let newSelectedIndex = allIdentifiedFeatures.indexOf(this.selectedFeature);
              let oldSelectedIndex = this.clickedFeatures.indexOf(this.selectedFeature);
              newPriorityFeatures = allIdentifiedFeatures.slice(0, newSelectedIndex);
              oldPriorityFeatures = this.clickedFeatures.slice(0, oldSelectedIndex);              

              if (!newPriorityFeatures.equals(oldPriorityFeatures)) {
                // Different (higher priority than currently selected) results returned since last identify.
                // Start selecting from top of these.
                selectedIndex = -1;
              }               
            }                                 

            this.clearSelectedFeatures();
            
            if (allIdentifiedFeatures.length > 0) {              
              let feature;

              if (selectedIndex < (allIdentifiedFeatures.length - 1)) {            
                feature = allIdentifiedFeatures[selectedIndex + 1];                            
              } else if (selectedIndex === (allIdentifiedFeatures.length - 1)) {
                feature = allIdentifiedFeatures[0];              
              }            

              if (feature) {
                feature.isSelected = true;
                if (feature.type === "system") {
                  this.addEvent("quickIdentify", this.getLayerPath(feature.layer));        
                }
                this.addNavItem([feature]);
                if (this.isFeatureTransformable(feature)) {                       
                  feature.transform = true;
                }
              }

              this.$emit("openMenuItem", "properties");                         
            }          
          }   

          this.clickedFeatures = allIdentifiedFeatures;                    
        }        
      })                      
    },    

    selectionOutlineColourChange(colour) {
      this.selectionOutlineColour = colour;      
      
      this.showFeatures();
      this.refreshIdentifyFeature();    
    },

    zoomToFitIdentifyRange() {
      this.goto({geometry: this.selectionGeometry.extent.clone().expand(this.extentPadding)});      
    },

    async zoomToFitFeatures(features) {
      let extent = this.getExtentForFeatures(features);        
      this.mapView.goTo({extent: extent});      
    },                 
    
    getLocatorSearches() {      

      let locatorSearches = this.searches.filter(search => search.locatorUrl);

      let objectIdPropertyName = "UPRN";

      let searches = locatorSearches.map(search => {        
        
        console.log(search);

        let subLayer = Vue.observable({
          id: search.id,
          globalId: "locator_" + search.id,
          title: "Address", 
          legend: [this.addressLegendItem],
          fields: [],
          objectIdPropertyName: objectIdPropertyName,  
          namePropertyName: "LOCATOR_DESCRIPTION",
          features: [],
          identifyResultsSortedField: null,
          identifyResultsSortedAscending: true,
          identifyResultsSearchSortedField: null,
          identifyResultsSearchSortedAscending: true,                
          includeInLayerManager: false,          
        });             
      
        let locatorSearch = Object.assign({}, search, { type: "locator", subLayer: subLayer, fieldsAndIndices: null });    
        return locatorSearch;
      })      

      return searches;
    },

    getOSSearches() {

      let search = Vue.observable({
        htmlTemplate: "<h6 class='small mb-1'>${ADDRESS}</h6><small class='mb-1 small text-muted'>UPRN:${UPRN}</small>",
        type: "OS",
        id: this.osAddressSearchId,
        placeHolder: "e.g NR12AS, 25LL",
        title: "Address",
        subLayer: {
          features: [],
          type: "OS",
          fields: 
          [
            {
              name: "UPRN",              
              alias: "UPRN",                      
              type: "esriFieldTypeString",
              identifyResultsFilterText: "",
              identifyResultsSearchFilterText: "",
              visibleOnMap: false,
              domain: null                          
            },
            {
              name: "ADDRESS",
              alias: "Address",        
              type: "esriFieldTypeString",
              identifyResultsFilterText: "",
              identifyResultsSearchFilterText: "",
              visibleOnMap: false,
              domain: null            
            },
            {
              name: "PARENT_UPRN",
              alias: "Parent UPRN",       
              type: "esriFieldTypeString", 
              identifyResultsFilterText: "",
              identifyResultsSearchFilterText: "",
              visibleOnMap: false,
              domain: null            
            },
            {
              name: "USRN",
              alias: "USRN",        
              type: "esriFieldTypeString",
              identifyResultsFilterText: "",
              identifyResultsSearchFilterText: "",
              visibleOnMap: false,
              domain: null            
            },
            {
              name: "TOWN_NAME",
              alias: "Town name",        
              type: "esriFieldTypeString",
              identifyResultsFilterText: "",
              identifyResultsSearchFilterText: "",
              visibleOnMap: false,
              domain: null            
            },
            {
              name: "WARD_CODE",
              alias: "Ward code",        
              type: "esriFieldTypeString",
              identifyResultsFilterText: "",
              identifyResultsSearchFilterText: "",
              visibleOnMap: false,
              domain: null            
            },
            {
              name: "PARISH_CODE",
              alias: "Parish code",       
              type: "esriFieldTypeString", 
              identifyResultsFilterText: "",
              identifyResultsSearchFilterText: "",
              visibleOnMap: false,
              domain: null            
            },
          ],
          globalId: this.osAddressLayerGlobalId + this.osAddressLayerId,
          id: this.osAddressLayerId,
          identifyResultsSearchSortedAscending: true,
          identifyResultsSearchSortedField: null,
          identifyResultsSortedAscending: true,
          identifyResultsSortedField: null,
          includeInLayerManager: false,          
          legend: [this.addressLegendItem],
          namePropertyName: "ADDRESS",
          objectIdPropertyName: "UPRN",
          title: "Address"
        }
      })      

      search.subLayer.identifyResultsSortedField = search.subLayer.fields.find(field => field.name === search.subLayer.objectIdPropertyName),        
      search.subLayer.identifyResultsSearchSortedField = search.subLayer.fields.find(field => field.name === search.subLayer.objectIdPropertyName)         

      return [search];
    },

    async loadLocatorFields(search) {    
        
      let locatorHub = new LocatorHub(search.locatorUrl);

      let columns;

      try {
        columns = await locatorHub.GetColumns(search.locatorGetColumnsSearchText);
      } 
      catch (e) {                              
                  
        this.errorMessage = e.message;          
        this.errorTitle = "Could not load locator hub fields.  Server maybe unavailable."          
        this.$refs.ErrorDialog.show();                 
        throw e;
      }       

      let fieldsAndIndices = [];
      
      search.propertyFields.forEach(propertyField => {
        let fieldIndex = columns.findIndex(column => propertyField === column.N);

        if (fieldIndex !== -1) {
          let field = this.locatorHubColumnToFeatureField(columns[fieldIndex])
          let fieldAndIndex = {
            field: field,
            index: fieldIndex
          }

          fieldsAndIndices.push(fieldAndIndex);
        }                    
      })
    
      let fields = fieldsAndIndices ? fieldsAndIndices.map(fieldAndIndex => fieldAndIndex.field) : [];    

      search.subLayer.fields = fields;           
      search.fieldsAndIndices = fieldsAndIndices;
      search.subLayer.identifyResultsSortedField = fields.find(field => field.name === search.subLayer.objectIdPropertyName),        
      search.subLayer.identifyResultsSearchSortedField = fields.find(field => field.name === search.subLayer.objectIdPropertyName)                           
    },

    onload2promise(obj) {
      return new Promise((resolve, reject) => {
        obj.onload = () => resolve(obj);
        obj.onerror = reject;
      });
    },

    async getBase64ImageDataUrl(url) {
      let img = new Image();

      img.setAttribute('crossOrigin', 'anonymous');
      
      let imgpromise = this.onload2promise(img);      
      img.src = url;
      await imgpromise;

      let canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      let ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);

      let dataURL = canvas.toDataURL("image/png");         

      return dataURL;
    },

    updateViewPadding() {
      this.mapView.padding = {
        left: this.componentsLeft,
        top: this.componentsTop,
        right: this.componentsRight,
        bottom: this.componentsBottom
      }
    },	

    async goto(args) {
      this.mapView.constraints.snapToZoom = false;
      await this.mapView.goTo(args);
    },

    identifyResultsSortedFieldChanged(layerItem, args) {
       if (layerItem === this.searchLayerItem) {
         layerItem.layer.identifyResultsSearchSortedField = args.field;
         layerItem.layer.identifyResultsSearchSortedAscending = args.ascending;
       } else {
         layerItem.layer.identifyResultsSortedField = args.field;
         layerItem.layer.identifyResultsSortedAscending = args.ascending;
      }
    },
    
    identifyResultsFieldFilterChanged(layerItem, args) 
    {
      let field = layerItem.layer.fields.find(field => field.name === args.field.name);

      if (layerItem === this.searchLayerItem) {
        field.identifyResultsSearchFilterText = args.value;
      } else {
        field.identifyResultsFilterText = args.value;
      } 
    },

    async searchTextChanged(text) {      
      this.searchText = text.trim();
      this.doSearch();      
    },

    async searchPointChanged(point) {
      this.searchPoint = point;
      this.showSearchFeatures();
    },

    async selectedSearchItemChanged(searchItemId) {      
      
      if (searchItemId) {

        let searchItem = this.searchItems.find(item => item.id === searchItemId);

        if (searchItem) {          

          if (searchItem.type === "arcGIS") {        

              let subLayer = searchItem.subLayer;
              let serviceLayer = subLayer.rootLayer;

              if (!subLayer.fieldsPromise) {        
                subLayer.fieldsPromise = this.loadFields(subLayer);                                        
              }
              
              try {
                await subLayer.fieldsPromise;
              } catch(e) {
                subLayer.fieldsPromise = null;
                throw e;
              }

              if (!serviceLayer.legendPromise) {
                serviceLayer.legendPromise = this.loadLegend(serviceLayer);          
              }              
              
              try {
                await serviceLayer.legendPromise
              } catch (e) {
                serviceLayer.legendPromise = null;
                throw e;
              }
          }          

        } else {
          searchItemId = null;
        }
      }       
      
      this.selectedSearchItemId = searchItemId; 
      await this.doSearch();      
    },
    
    async loadFields(subLayer) {            
      let url = subLayer.rootLayer.url + "/" + subLayer.id + "/?f=json";
      let response;

      try {        
        response = await axios.get(url);         
      }
      catch(e) {
        this.errorTitle = "Could not retrieve fields.  Server maybe unavailable"
        this.errorMessage = url;
        this.$refs.ErrorDialog.show();
        throw e;
      }

      subLayer.fields = this.agsSubLayerFieldsToVueSubLayerFields(response.data.fields);            
      let objectIdField = subLayer.fields.find(field => field.type === "esriFieldTypeOID")   
      
      // Default fields.
      subLayer.objectIdPropertyName = objectIdField.name;              
      subLayer.identifyResultsSortedField = objectIdField;       
      subLayer.identifyResultsSearchSortedField = objectIdField;   
      
      if (subLayer.fields) {
        subLayer.fields.forEach(field => {
          field.identifyResultsFilterText = "";
          field.identifyResultsSearchFilterText = "";
        })
      }
     
      // Load project settings for columns.

      if (this.lastLoadedProject) {
        this.restoreFieldsState(subLayer);         
      }
    },

    async doSubLayerSearch(subLayer, where, searchText) {            
      
      let results = await this.getFeatures(subLayer, where, searchText);

      let features = results.map(result => this.mergeFeature(subLayer, result));      

      if (features.length) {

        let nonLoadedGeometryFeatures = features.filter(feature => !feature.geometry);

        if (nonLoadedGeometryFeatures.length) { 
          await this.loadGeometryAndSymbolsForFeatures(nonLoadedGeometryFeatures);
        }                                                             
      }

      return { features: features, tooManyResults: false }   
    },    

    locatorHubColumnToFeatureField(column) {

      let field = {
        name: column.N,
        alias: column.N,        
        identifyResultsFilterText: "",
        identifyResultsSearchFilterText: "",
        visibleOnMap: false,
        domain: null
      }

      switch (column.T) {        
        case 0: {
          field.type = "esriFieldTypeInteger"
        }
        break;
        case 2: {
          field.type = "esriFieldTypeString";
        }
        break;
        case 4: {
          field.type = "esriFieldTypeDate"
        }
        break;
      }

      return field;
    },

    OSAddressToFeature(subLayer, address) {                 

      let filtered = Object.entries(address).filter(keyValue => {
        return subLayer.fields.find(field => field.name === keyValue[0]);
      })

      let attributes = {};

      filtered.forEach(keyValue => {
        this.$set(attributes, keyValue[0], keyValue[1]);        
      })                 

      let point = new this.agsPoint({
        x: address["X_COORDINATE"],
        y: address["Y_COORDINATE"],
        spatialReference: this.mapView.spatialReference
      });

      let defaults = this.defaults.systemFeature;
      let feature = JSON.parse(JSON.stringify(defaults));         
      
      feature.geometry = point;
      feature.attributes = attributes;      
      feature.geometry.typeEx = "point",
      feature.symbol = this.locator      
      feature.symbol = this.addressSymbol;
      
      return feature;
    },

    locatorHubRecordToFeature(record, fieldsAndIndices) {           

      let attributes = {};
      fieldsAndIndices.forEach(fieldAndIndex => {      
        attributes[fieldAndIndex.field.name] = record.fullshizzle[fieldAndIndex.index];
      });

      let point = new this.agsPoint({
          x: record.locatorPointX,
          y: record.locatorPointY,
          spatialReference: this.mapView.spatialReference
      });

      let defaults = this.defaults.systemFeature;
      let feature = JSON.parse(JSON.stringify(defaults));         
      
      feature.geometry = point;
      feature.attributes = attributes;      
      feature.geometry.typeEx = "point",
      feature.symbol = this.locator     
      feature.symbol = this.addressSymbol;      

      return feature;
    },

    async doLocatorHubSearch(searchItem, searchText) {            

      let locatorHub = new LocatorHub(searchItem.locatorUrl);

      let response;

      try {
        response = await locatorHub.Match( "","", searchText);   
      } catch (e) {
        throw "Could not perform locatorhub search.  " + e;
      }
      let features = [];            

      if (response.resultCode === 1) {
        return { features: features, tooManyResults: true }
      } else if (response.resultCode === 0)  { // Picklist 

        let items = response.items.filter(item => item.hasOwnProperty('id') && item.hasOwnProperty('cacheid'))
        let itemDetailPromises = items.map(item => locatorHub.Match(item.id, item.cacheid, searchText));      
        let itemDetails;
        try {
          itemDetails = await Promise.all(itemDetailPromises);                        
        } catch (e) {
          throw "Could not perform locatorhub details search.  " + e;
        }

        if (itemDetails.length > 0) {                       
                  
          features = itemDetails.map(item => {        
            return this.locatorHubRecordToFeature(item.record, searchItem.fieldsAndIndices);
          })                                                                      
        }         

      } else if (response.resultCode == 4) { // Single match.

        let feature = this.locatorHubRecordToFeature(response.record, searchItem.fieldsAndIndices);
        features.push(feature);

      }            

      return { features: features, tooManyResults: false }
    },       

    async doOSSearch(searchItem, searchText) {                       

      let os = new OSPlaces(import.meta.env.VITE_OS_PLACES_URL, this.msal, import.meta.env.VITE_MBAPI_AppIDURI);
      
      let results;

      try {
        results = await os.find(searchText, ['NCC'], ['wardCode','parishCode'], true, 100);
      } catch (e) {
        let error = new Error("Could not perform OS search.  Server maybe unavailable.");
        error.message = e.message;
        throw error;
      }

      let addresses = results.map(result => result.NCC);      
      let features = addresses.map(address => this.OSAddressToFeature(searchItem.subLayer, address));

      return { features: features, tooManyResults: false }
    },

    async doSearch() {

      this.searchResultsMessage = null;
    
      let searchText = this.searchText;                             

      [...this.searchResults].forEach(feature => {        
        feature.isSearchResult = false;        

        if (!this.isVisibleActive(feature.layer)) {      
          feature.isSelected = false;                    
          feature.transform = false;                    

          this.deleteNavItemFeature(feature);
        }      
      });                  

      if (this.selectedSearchItem && (searchText.length >= this.MINIMUM_SEARCH_TEXT_LENGTH)) {

        if (!this.$appGlobals.isRestoringState) {              
          this.addEvent("search", this.selectedSearchItem.title);                    
        }
        
        this.isSearching = true;
        this.searchMessage = "Searching..."

        try
        {           
          let results;          
          let subLayer = this.selectedSearchItem.subLayer;

          if (this.selectedSearchItem.type === "locator") {                            
            results = await this.doLocatorHubSearch(this.selectedSearchItem, searchText);       
          } else if (this.selectedSearchItem.type === "OS") {            
            results = await this.doOSSearch(this.selectedSearchItem, searchText);       
          } else {
            results = await this.doSubLayerSearch(subLayer, this.selectedSearchItem.whereStatement, searchText);          
          }                  

          if (results.tooManyResults) {

            this.searchResultsMessage = "Search too vague.  Too many results"

          } else {

            this.searchResultsMessage = null;

            let features = results.features; 
            
            features = features.filter(feature => {      
              return this.searchInclusionExtent.contains(feature.geometry) || this.searchInclusionExtent.extent.intersects(feature.geometry);
            })

            if (features.length === 0) {
              return;
            }

            if ((this.selectedSearchItem.type === "locator") || (this.selectedSearchItem.type === "OS")) {
              features.forEach(feature => {
                feature.layer = subLayer;    
                subLayer.features.push(feature);
                this.idToFeatureMap[this.getGlobalFeatureId(feature)] = feature;   
              })
            }            

            features.forEach(feature => {
              feature.isSearchResult = true;    
            })                                      

            if (!this.$appGlobals.isRestoringState) {            

              if (features.length === 1) {
                this.allFeatures.forEach(feature => {      
                  feature.isSelected = false                          
                  feature.transform = false 
                });
                let feature = features[0];

                if (this.canZoomToFit(feature)) {
                  this.gotoFeatureAndZoomToFit(feature);              
                } else {
                  this.goto(feature);      
                }

                feature.isSelected = true;                
                this.addNavItem([feature]);
              } 
              else {              
                let extent = this.getExtentForFeatures(features);                
                this.mapView.goTo({ extent: extent });                         
              }
            }                
              
            if (this.selectionGeometry) {
              
              features = features.filter(feature => { 
                return this.geometryContainsOrIntersects(this.selectionGeometry, feature.geometry);                
              });    

              features.forEach(feature => {          
                feature.isInSelectionRange = true;          
              });  
            }                               
          }  
        }
        catch(e) {
          this.errorTitle = "Could not perform search.  Server might be unavailable."
          this.errorMessage = e.message;
          this.$refs.ErrorDialog.show();
          throw e;
        }
        finally { 
          this.isSearching = false;
          this.searchMessage = "";          
        }   
      }
    },

    propertiesWindowSelectedItemChanged(item) {            
      this.allFeatures.forEach(feature => {      
        feature.isSelected = false;         
        feature.transform = false
      });      

      if (item) {              
        let selectable = item.value;                
        selectable.isSelected = true;        
        this.addNavItem([selectable]);
        if (this.isFeatureTransformable(selectable)) {                
          selectable.transform = true;
        }
      }              
    },        

    coordinateModeChanged(mode) {
      this.coordinateMode = mode;
      this.showFeatures();
    },

    async enforceServiceLayerOrder(serviceLayer) {      

      // system service layer;
      // Move after any private layers.

      /*console.log(this.layers.map(layer => layer.title))
      console.log(this.map.layers.items.map(layer => layer.title));      */

      // ags layer to match vue layer order.
      let vueIndex = this.layers.indexOf(serviceLayer);
      let previousloadedVueLayer;

      for (let i = vueIndex - 1; i >=0; i--) {        
        if (this.layers[i].loadPromise) {          
          previousloadedVueLayer = this.layers[i];          
          break;
        }
      }
      
      let previousVisibleAgsLayerIndex = -1;
      
      if (previousloadedVueLayer) { 
        previousVisibleAgsLayerIndex = this.map.layers.items.findIndex(agsLayer => agsLayer === previousloadedVueLayer.agsLayer);                              
      } 
            
      this.map.layers.reorder(serviceLayer.agsLayer, previousVisibleAgsLayerIndex + 1);          

      // console.log(this.layers.map(layer => layer.title))
      // console.log(this.map.layers.items.map(layer => layer.title));      
    },              

    showHelp(payload){
      this.helpUrl = payload
      this.$emit("openMenuItem", "help");
    },        

    recurseGroupLayer(groupLayer) {            

      let groupLayers = [];

      if (groupLayer.sublayers) {

        groupLayer.sublayers.forEach(childLayer => {
          
          if (childLayer.sublayers) {                    
            groupLayers.push(childLayer);          
          }

          groupLayers = groupLayers.concat(this.recurseGroupLayer(childLayer));          
        })      
      }

      return groupLayers;
    },    

    recurseSubLayer(subLayer) {            

      let subLayers = [];

      subLayer.sublayers.forEach(childSubLayer => {
        
        if (!childSubLayer.sublayers) {                    
          subLayers.push(childSubLayer);          
        } else {          
          subLayers = subLayers.concat(this.recurseSubLayer(childSubLayer));
        }
      })      

      return subLayers;
    },    

     reorderLayerItem(item, sibling) {      

      //console.log(this.layers.map(layer => layer.title))
      //console.log(this.map.layers.items.map(layer => layer.title));
      
      if (!item.parent) {        
        
        let agsService = this.map.layers.find(layer => layer.id === item.id);
        let siblingAgsService = this.map.layers.find(layer => layer.id === sibling.id);

        let agsServiceIndex = this.map.layers.indexOf(agsService);
        let siblingAgsServiceIndex = this.map.layers.indexOf(siblingAgsService);

        this.map.layers.reorder(agsService, siblingAgsServiceIndex);        

        let index = this.layers.indexOf(item);
        let siblingIndex = this.layers.indexOf(sibling);
        
        this.layers.splice(siblingIndex, 0, this.layers.splice(index, 1)[0]);        
      } 
      else
      {  
        // reorder vue.
        let index = item.parent.sublayers.indexOf(item);
        let siblingIndex = item.parent.sublayers.indexOf(sibling);
        
        item.parent.sublayers.splice(siblingIndex, 0, item.parent.sublayers.splice(index, 1)[0]);

        if (item.rootLayer.type === "arcGIS") {
          let agsSiblingIndex = item.agsLayer.parent.sublayers.indexOf(sibling.agsLayer);        
          item.agsLayer.parent.sublayers.reorder(item.agsLayer, agsSiblingIndex);          
        } else {
          let agsSiblingIndex = this.map.layers.indexOf(sibling.agsLayer);        
          this.map.layers.reorder(item.agsLayer, agsSiblingIndex);
        }        
      }

      //console.log(this.layers.map(layer => layer.title))
      // console.log(this.map.layers.items.map(layer => layer.title));
    },                    

    async addFeatureBookmark(feature, name) {

      if (!feature.geometry) {
        await this.loadGeometryAndSymbolsForFeatures([feature]);        
      } 
      
      this.createBookmark(feature, null, name, "Pin bookmark");
    },    
    
    async gotoFeatureAndZoomToFit(feature) {

      if (!feature.geometry) {
        await this.loadGeometryAndSymbolsForFeatures([feature]);        
      }      

      if (feature.layer && feature.layer.id === this.osAddressLayerId) {  // Special case for addresses.

        this.mapView.goTo({ 
          center: feature.geometry,    
          scale: 500
        });
      } else {

        if (feature.geometry.extent) {
          this.mapView.goTo({ 
            extent: feature.geometry.extent.clone().expand(1.4) // 15%
          });        
        } else {
          this.mapView.goTo({ 
            center: feature.geometry,    
          });
        }            
      }
    },    

    async gotoFeature(feature) {

      if (!feature.geometry) {
        await this.loadGeometryAndSymbolsForFeatures([feature]);        
      }      

      let args;      

      if (feature.geometry.extent) {
        args = { center: feature.geometry.extent.center }
      } else {
        args = { center: feature.geometry }
      }

      await this.mapView.goTo(args);
    },    

    mapPointToUIMapPoint(mapPoint) {
      let screenPoint = this.mapView.toScreen(mapPoint);
  
      let newScreenPoint = {
        x: screenPoint.x - (this.componentsLeft  - this.componentsRight) / 2,
        y: screenPoint.y - (this.componentsTop  - this.componentsBottom) / 2,
        spatialReference: screenPoint.spatialReference
      }        
      
      return this.mapView.toMap(newScreenPoint);      
    },  

    async getFeaturesById(subLayer, featureIds) {              
      
      if (!subLayer.fieldsPromise) {        
        subLayer.fieldsPromise = this.loadFields(subLayer);                                               
      }

      try {
        await subLayer.fieldsPromise;
      } catch(e) {
        subLayer.fieldsPromise = null;
        throw e;
      }

      let inClause = `${subLayer.objectIdPropertyName} IN (${featureIds.join(",")})`;                
      let url = subLayer.rootLayer.url + "/" + subLayer.id;

      let params = {
        id: subLayer.id + "_featureLayer",
        url: url,
        title: subLayer.title + "_featureLayer",
        outFields: ["*"],
        fullExtent: this.mapView.extent,
        definitionExpression: inClause,        
        visible: false
      };
      
      const featureLayer = new this.agsFeatureLayer(params);   
      this.map.layers.add(featureLayer);            

      try {
        await featureLayer.when();
      } 
      catch (e) 
      {
        this.map.layers.remove(featureLayer);

        this.errorTitle = "Could not load feature layer for geometry and symbols. Server maybe unavailable"
        this.errorMessage = url;
        this.$refs.ErrorDialog.show();
        throw e;
      }

      let query = featureLayer.createQuery();
      query.returnQueryGeometry = true;
      
      let response;     

      try {
        response = await featureLayer.queryFeatures(query);
      }
      catch (e) 
      {
        this.map.layers.remove(featureLayer);

        this.errorTitle = "Could not query features for geometry and symbols.  Server maybe unavailable"
        this.errorMessage = url;
        this.$refs.ErrorDialog.show();
        throw e;
      }

      let features = response.features;                  

      features.forEach(feature => {                                    
        feature.symbol = featureLayer.renderer.getSymbol(feature);
        this.deleteNotNeededAttributes(subLayer, feature);        
      });             

      this.map.layers.remove(featureLayer);            
      return features;      
    },      

    deleteNotNeededAttributes(subLayer, feature) {      
      
      let geometryField = subLayer.fields.find(field => field.type === "esriFieldTypeGeometry");

      if (geometryField) {
        if (feature.attributes[geometryField.name]) {
          delete feature.attributes[geometryField.name];        
        }
      }
    },

    async getFeaturesByIdAndMerge(layer, featureIds) {
      let features = await this.getFeaturesById(layer, featureIds);          

      features.forEach(feature => {
        this.mergeFeature(layer, feature);
      })           

      return features;
    },
    
    async loadGeometryAndSymbolsForFeatures(features)
    {            
      if (features.length === 0) {        
        return [];       
      }

      let layers = features.map(feature => feature.layer).distinct();
              
      let promises = layers.map(async layer => {
        let layerFeatures = features.filter(feature => feature.layer === layer);
        let featureIds = layerFeatures.map(feature => feature.attributes[layer.objectIdPropertyName]);
        return this.getFeaturesByIdAndMerge(layer, featureIds);                            
      });                         

      return Promise.all(promises);          
    },

    showSearchFeatures() {

      if (this.searchFeaturesGfxLayer) {

        let features = [...this.searchResults].reverse(); 
        
        this.searchFeaturesGfxLayer.graphics.removeAll();          

        features.forEach(feature => {
                        
          let transparant = {
            r: 0,
            g: 0,
            b: 0,
            a: 0
          } 
                    
          let geometry = feature.geometry;          
          let symbolGraphic;                 
          
          switch (feature.symbol.type) {
            case "simple-marker": // e.g. accidents.                

              let symbol = feature.symbol.clone();                                  
                
              symbolGraphic = new this.agsGraphic({                
                geometry: geometry,
                symbol: symbol
              });
                  
              this.searchFeaturesGfxLayer.add(symbolGraphic);      
              
              break;
            case "simple-fill":      // e.g. junior school catchments.
            case "picture-fill":            

              symbolGraphic = new this.agsGraphic({                
                geometry: geometry,
                symbol: feature.symbol
              });
                  
              this.searchFeaturesGfxLayer.add(symbolGraphic);               

              break;
            case "picture-marker":  // e.g. milestones
                  
              symbolGraphic = new this.agsGraphic({                
                geometry: geometry,
                symbol: feature.symbol
              });
                  
              this.searchFeaturesGfxLayer.add(symbolGraphic);    
        
              break;
            case "simple-line":     // e.g. a roads

              symbolGraphic = new this.agsGraphic({                
                geometry: geometry,
                symbol: feature.symbol
              });
                  
              this.searchFeaturesGfxLayer.add(symbolGraphic);                                              
              break;
          }                   
        })        
      }
    },
  
    showSelectedFeatures()
    {          
      if (this.selectedSystemFeaturesGfxLayer) {                                

        this.selectedSystemFeaturesGfxLayer.graphics.removeAll();        

        // Add selected features.
        let features = this.selectedSystemFeatures.filter(feature => feature.geometry && feature.symbol);
                
        features.forEach(feature => {
                      
          let transparant = {
            r: 0,
            g: 0,
            b: 0,
            a: 0
          } 
                    
          let geometry = feature.geometry;           
          let symbolGraphic;
          let highlightSymbol;
          let highlightGraphic;
         
          switch (feature.symbol.type) {
            case "simple-marker": // e.g. accidents.                            

              let symbol = feature.symbol.clone();                                             
              
              symbol.size = symbol.size + (symbol.outline ? symbol.outline.width : 0);
              symbol.outline = {
                color: this.selectionOutlineColour,      
                width: 3
              }                       
                
              symbolGraphic = new this.agsGraphic({                
                geometry: geometry,
                symbol: symbol,              
              });

              symbolGraphic.vueFeature = feature;
                  
              this.selectedSystemFeaturesGfxLayer.add(symbolGraphic);                                  
              
              break;
            case "simple-fill":      // e.g. junior school catchments.
            case "picture-fill":
          
              highlightSymbol = {
                type: "simple-fill",
                color: [79, 195, 247, 0.5],
                style: "solid",
                outline: {  
                  color: this.selectionOutlineColour,
                  width: 2
                }
              };

              highlightGraphic = new this.agsGraphic({                
                geometry: geometry,
                symbol: highlightSymbol,                             
              });
              highlightGraphic.vueFeature = feature;
                
              this.selectedSystemFeaturesGfxLayer.add(highlightGraphic);     

              break;
              
            case "picture-marker":  // e.g. milestones     
                  
              symbolGraphic = new this.agsGraphic({                
                geometry: geometry,
                symbol: feature.symbol
              });
                  
              this.selectedSystemFeaturesGfxLayer.add(symbolGraphic);                
                
              // Add a border around marker

              let border = {
                  type: "simple-marker",
                  style: "square",
                  color: transparant,
                  size: feature.symbol.width,
                  outline: {
                    color: this.selectionOutlineColour,
                    width: 3,                        
                }                                  
              }
              
              let borderGraphic = new this.agsGraphic({                
                geometry: feature.geometry,
                symbol: border,                         
              });
              borderGraphic.vueFeature = feature;
              
              this.selectedSystemFeaturesGfxLayer.add(borderGraphic);       

              break;

            case "simple-line":     // e.g. a roads        

              symbolGraphic = new this.agsGraphic({                
                geometry: geometry,
                symbol: feature.symbol
              });
                  
              this.selectedSystemFeaturesGfxLayer.add(symbolGraphic);                           
                            
              highlightSymbol = feature.symbol.clone();

              highlightSymbol.color = this.selectionOutlineColour;               

              highlightGraphic = new this.agsGraphic({                
                geometry: geometry,
                symbol: highlightSymbol,            
              });

              highlightGraphic.vueFeature = feature;

              this.selectedSystemFeaturesGfxLayer.add(highlightGraphic);                                                
              break;
          }    
          
          let labelsGraphic = this.getLabelGraphics(feature);
          labelsGraphic.forEach(graphic => {                          
            this.selectedSystemFeaturesGfxLayer.add(graphic);            
          })          
        })                   
      }                       
    },                  
    
    getLabelGraphics(feature, isCreating) {
                        
      let centerLabels = []
      let graphics = [];

      let locationVisibleOnMap;
      let lengthVisibleOnMap;
      let areaVisibleOnMap;
      let sideLengthsVisibleOnMap;
      let nameVisibleOnMap;      
      let lengthUnit;
      let areaUnit;
      
      let geometryType = this.getGeometryType(feature);  

      if (feature.type === "system") {
        lengthUnit = this.lengthUnit;
        areaUnit = this.areaUnit;        
      } else {
        lengthUnit = feature.lengthUnit;
        areaUnit = feature.areaUnit;        
      }

      if (isCreating) {
        if (geometryType === "polygon" || geometryType === "rectangle" || geometryType === "polyline") {
          sideLengthsVisibleOnMap = true;
        }        
      } else {
        if (feature.type === "userFeature" || feature.type === "measurement") {
          locationVisibleOnMap = feature.locationVisibleOnMap;
          lengthVisibleOnMap =  feature.lengthVisibleOnMap;
          sideLengthsVisibleOnMap = feature.sideLengthsVisibleOnMap;
          areaVisibleOnMap = feature.areaVisibleOnMap;

          if (feature.type === "measurement" && geometryType === "point") {
            locationVisibleOnMap = true;            
          }
        }          
        else
        {
          locationVisibleOnMap = this.locationVisibleOnMap;          

          if (geometryType !== "point") {
            
            lengthVisibleOnMap =  this.lengthVisibleOnMap;              
          }

          if (geometryType === "polygon" || geometryType === "circle" || geometryType === "rectangle") {           
            areaVisibleOnMap = this.areaVisibleOnMap;
          }
        }                

        if (feature.type === "userFeature") {
          nameVisibleOnMap = feature.attributesVisibleOnMap.indexOf(feature.layer.namePropertyName) !== -1;        
        } else if (feature.type === "system") {          
          if (feature.layer.namePropertyName) {
            nameVisibleOnMap = feature.layer.fields.find(field => field.name === feature.layer.namePropertyName).visibleOnMap;      
          }
        }

        if ((geometryType === "polyline") &&         
            (sideLengthsVisibleOnMap) && 
            (feature.geometry.paths.length > 0 && feature.geometry.paths[0].length < 3)) {        
          {
            lengthVisibleOnMap = false;
          }
        }
      }      

      if (nameVisibleOnMap) {        
        let value = feature.attributes[feature.layer.namePropertyName];
        if (value) {
          centerLabels.push(value);
        }                 
      }

      if (locationVisibleOnMap) {
        let location = this.getLocationString(feature, this.coordinateMode);
        centerLabels.push(location);
      }          

      if (lengthVisibleOnMap) {
        let length = this.getLengthString(feature.geometry, lengthUnit);
         centerLabels.push(length + " " + this.getLengthSuffix(lengthUnit))          
      }

      if (areaVisibleOnMap) {
        let area = this.getAreaString(feature, areaUnit);
        centerLabels.push(area + " " + this.getAreaSuffix(areaUnit))          
      }       
              
      let visibleFields = [];

      if (feature.type === "system") {
        visibleFields = feature.layer.fields.filter(field => field.visibleOnMap);                     
      } else if (feature.type === "userFeature") {
        visibleFields = feature.attributesVisibleOnMap.map(fieldName => {
          return feature.layer.fields.find(field => field.name === fieldName);
        })
      }
      
      visibleFields = visibleFields.filter(field => field.name !== feature.layer.namePropertyName);
      visibleFields.forEach(field => {
        let value = feature.attributes[field.name];

        if (value) {
          value = this.getFeatureCodedValue(feature, field, value);      
          if (field.type === "esriFieldTypeDate") {        
            value = moment(value).format("DD/MM/YYYY");
          } 

          if (typeof(value) === "string") {
            value = value.trim();
          }

          if (value) {
            centerLabels.push(field.alias + ": " + value);
          }                  
        }
      })

      if (centerLabels.length > 0) {
    
        let labelsText = centerLabels.join("\n");

        let symbol = JSON.parse(JSON.stringify(this.featuresLabelSymbol));      
        symbol.text = labelsText;  

        if (sideLengthsVisibleOnMap) {
          symbol.font.size = 18;
        }

        if (feature.geometry.type === "point") {
          symbol.verticalAlignment = "bottom";
        }

        // label geometry.
        let geometry;

        if (geometryType === "polyline") {
          let distance = getPolylineDistance(feature.geometry);
          geometry = getPointAlongPolyline(feature.geometry, distance / 2, this.agsPoint);
        } else {
          geometry = feature.geometry;
        }                                  

        if (geometry.extent) {
          geometry = geometry.extent.center;
        }

        let graphic = new this.agsGraphic({  
          geometry: geometry,
          symbol: symbol,                  
        });   
        
        graphics.push(graphic);
      }          

      if (sideLengthsVisibleOnMap) {

        if (this.getGeometryType(feature) === "polygon" || this.getGeometryType(feature) === "rectangle") {              

          let polylines = this.splitPolygonIntoPolylines(feature.geometry);

          polylines.forEach(polyline => {

            let splitLines = this.splitPolygonPolyline(polyline);
            this.addPolylineSymbols(lengthUnit, feature, splitLines, graphics);
          })            

        } else if (this.getGeometryType(feature) === "polyline") {
                    
          if (feature.geometry.paths.length > 0 ) {
            let splitLines = this.splitPolylinePath(feature.geometry.paths[0]);            
            this.addPolylineSymbols(lengthUnit, feature, splitLines, graphics);
          }          
        }
      }

      return graphics;
    },

    addPolylineSymbols(lengthUnit, feature, polylines, graphics) {
      polylines.forEach(polyline => {

        let distance = getPolylineDistance(polyline);
        if (distance > 0) {
          let geometry = getPointAlongPolyline(polyline, distance / 2, this.agsPoint);
          let symbol = JSON.parse(JSON.stringify(this.featuresLabelSymbol));                
      
          let length = this.getLengthString(polyline, lengthUnit);
      
          symbol.text = length + " " + this.getLengthSuffix(lengthUnit);                          

          let graphic = new this.agsGraphic({  
            geometry: geometry,
            symbol: symbol,                  
          });           

          graphics.push(graphic);                                      
        }
      })          
    },

    splitPolygonPolyline(polyline) {            

      let splitLines = polyline.paths.map(path => {

        let splitPath = [...path];

        let splitLine = new this.agsPolyline({
          hasZ: false,
          hasM: false,
          paths: [[...splitPath]],
          spatialReference: this.mapView.spatialReference
        })                        

        return splitLine;
      })
    
      return splitLines;
    },

    splitPolylinePath(path) {
      let polylines = [];

      for (var pointIndex = 1; pointIndex < path.length; pointIndex++) {        
        let x1 = path[pointIndex-1][0];  
        let y1 = path[pointIndex-1][1];  
        let x2 = path[pointIndex][0];  
        let y2 = path[pointIndex][1];            
        
        let newPath = [[x1, y1],[x2, y2]];                  

        let polyline = new this.agsPolyline({
          hasZ: false,
          hasM: false,
          paths: [newPath],
          spatialReference: this.mapView.spatialReference
        })

        polylines.push(polyline);
      }

      return polylines;      
    },

    splitPolygonIntoPolylines(polygon) {

      let polylines = polygon.rings.map(ring => {    
        return this.ringToPolyline(ring);
      })      
      
      return polylines;
    },

    ringToPolyline(ring) {
      
      let paths = [];
      let x1, x2, y1, y2;
      
      for (var pointIndex = 1; pointIndex < ring.length; pointIndex++) {        
        x1 = ring[pointIndex-1][0];  
        y1 = ring[pointIndex-1][1];  
        x2 = ring[pointIndex][0];  
        y2 = ring[pointIndex][1];            

        paths.push([[x1, y1],[x2, y2]]);                  
      }        

      x1 = ring[ring.length-1][0];  
      y1 = ring[ring.length-1][1];  
      x2 = ring[0][0];  
      y2 = ring[0][1]; 

      paths.push([[x1, y1],[x2, y2]]);      

      let polyline = new this.agsPolyline({
        hasZ: false,
        hasM: false,
        paths: paths,
        spatialReference: this.mapView.spatialReference
      })

      return polyline;
    },

    identifySelectionGeometryChanged()
    {       
      let inSelectionRange = this.featuresInSelectionRange.slice();
      
      inSelectionRange.forEach(feature => {
        feature.isInSelectionRange = false;        
      });              
      
      this.identifyInSelection();            
    },
    
    selectedActiveLayerItemIdChanged(layerItemId) {                
      this.identifyLayerItemId = layerItemId;       
    },

    async searchSelectFeatures(features) {
      await this.selectFeatures(features);

      if (features.length === 1) {
        if (this.$appGlobals.isLargeScreen) {
          this.$emit("openMenuItem", "properties");
        }
      }
    },

    async identifyResultsSelectFeatures(features) {
      this.clearSelectedFeatures();
      await this.selectFeatures(features);

      if (features.length === 1) {
        this.$emit("openMenuItem", "properties");
      }
    },

    async selectFeatures(features) {                        
      
      if (features.length > 0) { 
        
        let nonLoadedGeometryFeatures = features.filter(feature => !feature.geometry);
        let selectedFeatureChangeRequestPromise;

        if (nonLoadedGeometryFeatures.length) {           
          selectedFeatureChangeRequestPromise = this.loadGeometryAndSymbolsForFeatures(nonLoadedGeometryFeatures);                        

          this.latestSelectedFeatureChangeRequestPromise = selectedFeatureChangeRequestPromise;

          await selectedFeatureChangeRequestPromise;
            
          if (selectedFeatureChangeRequestPromise !== this.latestSelectedFeatureChangeRequestPromise) {
            // prevents more than one feature being selected.
            return;
          }        
        }  
      } 

      this.clickedFeatures = [];                  
      this.allFeatures.forEach(feature => {      
        feature.isSelected = false               
        feature.transform = false 
      });

      features.forEach(feature => { 
        feature.isSelected = true;        
        if (this.isFeatureTransformable(feature)) {          
          feature.transform = true; 
        }                        
      });               

      if (features.length > 0) {
        this.addNavItem(features);
      }
    },

    filteredAndSortedFeaturesChanged(args) {      
      let hiddenFeatures = args.oldShownFeatures.diff(args.newShownFeatures);
            
      if (!this.selectedFeature) {
        hiddenFeatures.forEach(feature => {                  
          feature.isSelected = false;             
          feature.transform = false;
        })
      }          
  },          

  mergeFeature(subLayer, agsFeature) {      
            
      let globalFeatureId = subLayer.globalId + "_MBID_" + agsFeature.attributes[subLayer.objectIdPropertyName];
      let vueFeature = this.idToFeatureMap[globalFeatureId];

      if (!vueFeature) {
        let defaults = this.defaults.systemFeature;
        vueFeature = JSON.parse(JSON.stringify(defaults));         
       
        vueFeature.layer = subLayer;                                                                      
        this.idToFeatureMap[globalFeatureId] = vueFeature;   
        subLayer.features.push(vueFeature);      
      }

      if (agsFeature.attributes) {        

        Object.keys(agsFeature.attributes).forEach(key => {       
          this.$set(vueFeature.attributes, key, agsFeature.attributes[key]);                                   
        })               
      }

      if (agsFeature.geometry) {
        vueFeature.geometry = agsFeature.geometry;
        vueFeature.geometry.typeEx = agsFeature.geometry.type;
      }
      
      if (agsFeature.symbol) {
        vueFeature.symbol = agsFeature.symbol;
      }

      return vueFeature; 
   },

    mergeIntoFeatures(agsFeatureResults) {              

      let vueFeatures = [];

      agsFeatureResults.forEach(serviceAndResults => {                

        let serviceLayer = serviceAndResults.serviceLayer;                
        serviceAndResults.results.forEach(agsResult => {                  

          let subLayer = serviceLayer.allSublayers.find(subLayer => subLayer.id === agsResult.layerId);                  
          let agsFeature = agsResult.feature;                                                      
          let vueFeature = this.mergeFeature(subLayer, agsFeature)                        
          vueFeatures.push(vueFeature);      
        })
      })
                  
      return vueFeatures;
    },    

    async getFeatures(subLayer, columns, searchText) {

      let url = subLayer.rootLayer.url + "/" + subLayer.id;

      let query = new this.agsQuery();

      searchText = searchText.replace("\'", "\'\'");
      
      query.returnGeometry = false;
      query.outFields = "*";                      
      query.where = columns.replace(/@@@/g, searchText);           
      
      let results = await this.agsQueryTask.executeQueryJSON(url, query);      
      
      results.features.forEach(feature => {
        this.deleteNotNeededAttributes(subLayer, feature);
      })

      return results.features;
    },

    isSubLayerVisibleInZoomRange(subLayer) 
    {
      return ((!this.isSubLayerTooZoomedIn(subLayer)) &&
              (!this.isSubLayerTooZoomedOut(subLayer)))
    },

    isSubLayerTooZoomedIn(subLayer) {
      return (subLayer.maxScale > 0) ? this.mapView.scale < subLayer.maxScale : false;
    },

    isSubLayerTooZoomedOut(subLayer) {
      return (subLayer.minScale > 0) ? this.mapView.scale > subLayer.minScale : false;
    }, 

    pointToExtent(mapView, point, toleranceInPixel) {      
      //calculate map coords represented per pixel
      var pixelWidth = mapView.extent.width / mapView.width;
      //calculate map coords for tolerance in pixel
      var toleraceInMapCoords = toleranceInPixel * pixelWidth;      

      return new this.agsExtent(point.x - toleraceInMapCoords,
                  point.y - toleraceInMapCoords,
                  point.x + toleraceInMapCoords,
                  point.y + toleraceInMapCoords,
                  mapView.map.spatialReference );
    },
        
    async identifyFeatures(layers, geometry, tolerance = 0) {      
      // returns an array of ags feature results.                  
      
      let serviceAndSubLayers = new Map();

      layers.forEach(subLayer => {
        if (!serviceAndSubLayers.has(subLayer.rootLayer)) {
          serviceAndSubLayers.set(subLayer.rootLayer, []);                        
        }
        
        serviceAndSubLayers.get(subLayer.rootLayer).push(subLayer);
      })            

      let serviceLayers = Array.from(serviceAndSubLayers.keys());

      let promiseToServiceLayer = new Map();

      let promises = serviceLayers.map(serviceLayer => {

        let params = new this.agsIdentifyParameters({                  
          layerOption: "all",
          tolerance: tolerance,
          width : this.mapView.width,
          height : this.mapView.height,
          geometry : geometry,
          mapExtent : this.mapView.extent,
          returnFieldName: true,
          returnUnformattedValues: true,
          returnGeometry :false
        });       

        let layerIds = serviceAndSubLayers.get(serviceLayer).map(subLayer => subLayer.id);
        params.layerIds = layerIds;                
                
        let promise = this.agsIdentify.identify(serviceLayer.url, params);        
        promiseToServiceLayer.set(promise, serviceLayer);
        return promise;
      })        

      try {

        let response = await this.agsPromiseUtils.eachAlways(promises);        
        let erroredResult = response.find(result => !result.value);

        if (erroredResult) {
          let serviceLayer = promiseToServiceLayer.get(erroredResult.promise);

          throw {
            serviceLayer: serviceLayer,            
          }
        }        
        let serviceAndResults = response.map((responseResult) => {            

          let serviceLayer = promiseToServiceLayer.get(responseResult.promise);
          let results = responseResult.value.results.map(agsResult => agsResult);                             
          results.forEach(result => {                        
            let subLayer = serviceLayer.allSublayers.find(subLayer => subLayer.id === result.layerId);              
            this.deleteNotNeededAttributes(subLayer, result.feature);
          })

          return { 
            serviceLayer: serviceLayer, 
            results: results,          
          }
        })
        
        return serviceAndResults;

      } catch(e) {                
        console.error(e);
        this.errorTitle = "Could not identify features.  Server maybe unavailable"
        this.errorMessage = e.serviceLayer.url;
        this.$refs.ErrorDialog.show();        
        throw e;        
      }      
    },

    openMapSheet() {
      this.addEvent("openMapSheet");            

      let gridSquare = this.getMapSheetReference(this.lastPointerDownMapPoint);
      let link = "https://maps.norfolk.gov.uk/definitivemaps/" + gridSquare + ".pdf";
      window.open(link, '_blank');      
    },

    identifyInSelectedFeature() {      
      
      let symbol = this.getIdentifySymbol(this.selectedFeature.geometry.type);
                   
      let graphic = new this.agsGraphic({                
        geometry: this.selectedFeature.geometry,
        symbol: symbol,              
      });      

      let feature = Vue.observable({
        type: "identify",      
        agsFeature: graphic,
        bufferGraphic: null
      });          

      this.identifyGfxLayer.removeAll();
      this.identifyGfxLayer.graphics.add(graphic);         

      let bufferGraphic = this.getIdentifyBufferGraphic(graphic.geometry);
      if (bufferGraphic) {
        feature.bufferGraphic = bufferGraphic;
        this.identifyGfxLayer.add(bufferGraphic);
      }
      
      this.identifyFeature = feature;                   
      this.identifySelectionGeometryChanged();
       this.$emit("openMenuItem", "results");       
    },

    async doIdentifyInSelection() {          

      let features = [];      
    
      if (this.identifyLayerItem && this.selectionGeometry) {            

        if (this.identifyLayerItem === this.searchLayerItem) {          
          
          features = this.searchResults.filter(feature => { 
            return this.geometryContainsOrIntersects(this.selectionGeometry, feature.geometry);             
          });    

          if (!this.$appGlobals.isRestoringState) {                            
            this.addEvent("rangeIndentify", this.getLayerPath(this.searchLayerItem.layer) + " (" + this.selectionGeometry.type + ")");          
          }
        } 
        else if (this.identifyLayerItem.layer.rootLayer === this.privateLayersGroup) {          

           features = this.identifyLayerItem.layer.features.filter(feature => {             
            return this.geometryContainsOrIntersects(this.selectionGeometry, feature.geometry);                         
          });            
        }
        else if (this.identifyLayerItem === this.userFeaturesLayerItem) {

           features = this.userFeatures.filter(feature => { 
            return this.geometryContainsOrIntersects(this.selectionGeometry, feature.geometry);                         
          });  
        } else {    

          let agsFeatureResults = await this.identifyFeatures([this.identifyLayerItem.layer], this.selectionGeometry);          
          features = this.mergeIntoFeatures(agsFeatureResults);                      

          if (!this.$appGlobals.isRestoringState) {                            
            this.addEvent("rangeIndentify", this.getLayerPath(this.identifyLayerItem.layer) + " (" + this.selectionGeometry.type + ")");          
          }
        }                      
      }

      return features;
    },
 
    async identifyInSelection() {      

      if (this.featuresInSelectionRange.length > 0) {
        for (let i = this.featuresInSelectionRange.length -1; i--; i >= 0) {
          this.featuresInSelectionRange[i].isInSelectionRange = false;
        }
      }

      let rangeIdentifyFeaturesPromise = this.doIdentifyInSelection();
      this.latestRangeIdentifyFeaturesPromise = rangeIdentifyFeaturesPromise;

      this.isIdentifying = true;               
      try
      {         
        let features = await rangeIdentifyFeaturesPromise;        

        if (rangeIdentifyFeaturesPromise === this.latestRangeIdentifyFeaturesPromise) {
          features.forEach(feature => {          
            feature.isInSelectionRange = true;          
          });          
        }
      }
      finally {
         this.isIdentifying = false; 
      }
    },

    updateBookmarkSymbols(bookmarks) {

      let bookmarkGraphics = this.bookmarksGfxLayer.graphics.filter(graphic => {
        return (graphic.attributes && graphic.attributes.mappingBrowserType === "bookmarkSymbol");
      })

      bookmarks.forEach(bookmark => {                

        let graphic = bookmarkGraphics.find(graphic => {
          return (graphic.attributes.name === bookmark.name);
        })

        if (!graphic) 
        { 
          let bookmarkGeometry = {
            type: "point",  // autocasts as new Point()            
            spatialReference: this.mapView.spatialReference
          };

          graphic = new this.agsGraphic({
            attributes: {
              name: bookmark.name,
              mappingBrowserType: "bookmarkSymbol"
            },
            geometry: bookmarkGeometry,
            symbol: this.bookmarkSymbol
          });

          this.bookmarksGfxLayer.graphics.add(graphic);
        }               
        
        graphic.geometry.x =  bookmark.coords.x;
        graphic.geometry.y =  bookmark.coords.y;        
      })      

      bookmarkGraphics.forEach(graphic => {
        
        if (!bookmarks.find(bookmark => bookmark.name === graphic.attributes.name)) {
          this.bookmarksGfxLayer.graphics.remove(graphic);          
        }
      })
    },

    updateBookmarkLabels(bookmarks) {

      let bookmarkGraphics = this.bookmarksGfxLayer.graphics.filter(graphic => {
        return (graphic.attributes && graphic.attributes.mappingBrowserType === "bookmarkText");
      })

      bookmarks.forEach(bookmark => {                

        let graphic = bookmarkGraphics.find(graphic => {
          return (graphic.attributes.name === bookmark.name);
        })

        if (!graphic) 
        { 
          let bookmarkGeometry = {
            type: "point",  // autocasts as new Point()            
            spatialReference: this.mapView.spatialReference
          };

          let bookmarkText = {
            type: "text",  // autocasts as new TextSymbol()
            color: "white",
            haloColor: "black",
            haloSize: "1px",
            text: bookmark.name,            
            yoffset: 26,
            font: {  // autocast as new Font()
              size: 16,
              family: "sans-serif",
              weight: "bold"
            }
          }

          graphic = new this.agsGraphic({
            attributes: {
              name: bookmark.name,
              mappingBrowserType: "bookmarkText"
            },
            geometry: bookmarkGeometry,
            symbol: bookmarkText
          });

          this.bookmarksGfxLayer.graphics.add(graphic);
        }               
        
        graphic.geometry.x =  bookmark.coords.x;
        graphic.geometry.y =  bookmark.coords.y;        
      })      

      bookmarkGraphics.forEach(graphic => {
        
        if (!bookmarks.find(bookmark => bookmark.name === graphic.attributes.name)) {
          this.bookmarksGfxLayer.graphics.remove(graphic);          
        }
      })      
    },

    updateBookmarksOnMap() {
      this.updateBookmarkSymbols(this.visibleBookmarks);
      this.updateBookmarkLabels(this.visibleBookmarks);      
    },   

    contextMenuDownAway()
    {
      this.contextMenuPopoverVisible = false;
    },

    pinBookmark() {                    
      this.createBookmark(null, this.lastPointerDownMapPoint, "", "Pin bookmark here");
    },

    copyCoordsToClipboard() {      
      let coordsText = agsPointToCoordString(this.lastPointerPos, this.coordinateMode);            
      this.copyToClipboard(coordsText);
    },

    pinBookmarkCenter() {                              
      this.createBookmark(null, this.mapView.center, "", "Pin bookmark");
    },    

    createBookmark(feature, point, name, createBookmarkDialogTitle) {

      let geometry;

      if (point) {
        geometry = point;
      } else {        
        if (feature.geometry.extent) {
           geometry = feature.geometry.extent.center;
        } else {        
          geometry = feature.geometry;          
        }
      }
      
      this.createBookmarkName = name;      
      this.createBookmarkDialogTitle = createBookmarkDialogTitle;
      this.featureForCreateBookmark = feature;        
      this.createBookmarkCoords = geometry;
      this.$refs.CreateBookmarkDialog.show();            
    },
    
    async loadLegend(serviceLayer) {            

      let url = serviceLayer.url + "/legend/?f=json";
      let response;

      try {    
        response = await axios.get(url);        
      }
      catch(e) {
        this.errorTitle = "Could not retrieve legend.  Server maybe unavailable"
        this.errorMessage = url;
        this.$refs.ErrorDialog.show();
        throw e;
      }
      
      response.data.layers.forEach(layer => {    
                  
        layer.legend.forEach(legend => {
          let subLayer = serviceLayer.allSublayers.find(item => {return item.id === layer.layerId});
          if (!subLayer.legend) {
            subLayer.legend = [];
          };
          
          subLayer.legend.push({
            label: legend.label,
            imageDataUrl: "data:" + legend.contentType + ';base64,' + legend.imageData            
          })
        })                                   
      })                        
    },          

    //--------------------------------------------------------------------------------------------------------
    // Set Active View Rotation
    //--------------------------------------------------------------------------------------------------------
    setViewRotation(payload){
       this.mapView.goTo({
           rotation: payload   
       });  
    },    

    setMapScale(payload){            
      this.mapView.goTo({
          scale: payload   
      });   

      return true;
    },

    //--------------------------------------------------------------------------------------------------------
    // Toggle a UI Element's visibility
    //--------------------------------------------------------------------------------------------------------
    toggleUIElementVisibiliy(payload){
      
      switch(payload){
        case "mapOverview":
          this.overviewVisible=!this.overviewVisible;
          break;
        case "mapCompass":
          this.compassVisible=!this.compassVisible;
          break;
        case "mapNavigation":
          this.navigationVisible=!this.navigationVisible;
          break;
        case "mapScaleBar":
          this.scalebarVisible=!this.scalebarVisible;
          break;
        
      }
    },    

    agsSubLayerFieldsToVueSubLayerFields(agsFields) {
      
      // handle a filthy hack where the alias property has been hijacked with a column order string.      

      let vueFields = [];

      if (agsFields) {                                                             
      
        let idField = agsFields.find(field => field.type === "esriFieldTypeOID");
        let orderField = agsFields.find(field => field.alias.startsWith("mbFieldOrder") || field.alias.startsWith("FIELD_ORDER"));

        if (idField) {

          vueFields.push({ 
            name: idField.name,
            alias: idField === orderField ? idField.name : idField.alias,
            type: idField.type,
            identifyResultsFilterText: "",
            identifyResultsSearchFilterText: "",
            visibleOnMap: false,
            domain: null
          })

          agsFields.splice(agsFields.indexOf(idField), 1);
        }
        
        let orderedFields = [];

        if (orderField) {
          let order = orderField.alias.split(",");
          order.shift();          

          order.forEach(fieldAlias => {
            let field = agsFields.find(field => field.alias === fieldAlias);
            if (field) {
              orderedFields.push(field);
              agsFields.splice(agsFields.indexOf(field), 1);
            }            
          })
        } 

        orderedFields = orderedFields.concat(agsFields);
                        
        orderedFields.forEach(field => {                
          vueFields.push({ 
            name: field.name,
            alias: field === orderField ? field.name : field.alias,
            type: field.type,
            identifyResultsFilterText: "",
            identifyResultsSearchFilterText: "",
            visibleOnMap: false,
            domain: field.domain ? {
              name: field.domain.name,
              type: field.domain.type,
              codedValues: field.domain.codedValues.map(value => {
                return {
                  name: value.name,
                  code: value.code,                
                }           
              }) 
            } : null      
          })              
        })                                    
      }

      return vueFields;
    },
    
    recurseAgsLayerFromVueLayer(vueLayer, agsLayer) {
      
      vueLayer.agsLayer = agsLayer;      
      
      // ags layer to match vue layer order.
      let index = vueLayer.parent.sublayers.indexOf(vueLayer);
      agsLayer.parent.sublayers.reorder(agsLayer, index);              
      
      if (vueLayer.sublayers) {                      
                
        vueLayer.sublayers.forEach(vueChild => {                
          let agsChild = agsLayer.sublayers.find(agsSubLayer => agsSubLayer.id === vueChild.id);
          this.recurseAgsLayerFromVueLayer(vueChild, agsChild);
        })            
      }
    },

    getChildLeafLayers(layer){     

      if (layer.sublayers) {  
        let children = layer.sublayers.map(childLayer => {
          return this.getChildLeafLayers(childLayer);
        });        
                
        return children.flat();
      } else {        
        return [layer];
      }
    },
    
    async loadServiceLayer(serviceLayer) {

      let mapImageLayer = new this.agsMapImageLayer({
                                id: serviceLayer.id,
                                url: serviceLayer.url, 
                                title: serviceLayer.title,  
                                internal: false              
                            });                                                   

      try {    
        this.map.layers.add(mapImageLayer);        
        await mapImageLayer.when();        
      }      
      catch(e)
      {        
        this.errorTitle = "Could not retrieve data to make layer visible.  Server maybe unavailable"
        this.errorMessage = serviceLayer.url;
        this.$refs.ErrorDialog.show();          
        throw e;
      }                                                                              
                          
      serviceLayer.agsLayer = mapImageLayer;            
    },   

    async loadServiceLayerDeps(serviceLayer) {            
      
      let subLayers = this.getChildLeafLayers(serviceLayer);
      let visibleActiveSubLayers = subLayers.filter(subLayer => this.isVisibleActive(subLayer));

      if (serviceLayer.type === "arcGIS" && visibleActiveSubLayers.length > 0) {        

        let servicePromises = [];

        if (!serviceLayer.loadPromise) {          
          serviceLayer.loadPromise = this.loadServiceLayer(serviceLayer);
          servicePromises.push(serviceLayer.loadPromise);
        } else {
          servicePromises.push(null);
        }

        if (!serviceLayer.legendPromise) {          
          serviceLayer.legendPromise = this.loadLegend(serviceLayer);
          servicePromises.push(serviceLayer.legendPromise);
        } else {
          servicePromises.push(null);
        }      

        let settled = Promise.allSettled(servicePromises);                
        let results = await settled;        

        results.forEach((result, i) => {

          let promise = servicePromises[i];

          if (result.status === "rejected") {            

            if (serviceLayer.loadPromise === promise) {
              serviceLayer.loadPromise = null;
            }

            if (serviceLayer.legendPromise === promise) {
              serviceLayer.legendPromise = null;
            }
          }
        })
        
        let errored = results.find(result => result.status === "rejected");
        if (errored) {          
          throw new Error(results[0].reason);
        }        
      }                      
            
      let subLayerPromises = visibleActiveSubLayers.map(subLayer => {
        
        let promise = null;

        if (serviceLayer.type === "arcGIS") {
          if (!subLayer.fieldsPromise) {                    
            subLayer.fieldsPromise = this.loadFields(subLayer);                                              
            promise = subLayer.fieldsPromise;
          }   
        } else { // private layer
          if (!subLayer.loadPromise) {                        
            subLayer.loadPromise = this.loadFeatures(subLayer);            
            promise = subLayer.loadPromise;
          }              
        }                

        return promise;
      })                
            
      let settled = Promise.allSettled(subLayerPromises);      
      let results = await settled;      

      results.forEach((result, i) => {

        let promise = subLayerPromises[i];
        let subLayer = visibleActiveSubLayers[i];
        if (result.status === "rejected") {
          
          if (subLayer.fieldsPromise === promise) {
            subLayer.fieldsPromise = null;
          }

          if (subLayer.loadPromise === promise) {
            subLayer.loadPromise  = null;
          }
        }        
      })           

      let errored = results.find(result => result.status === "rejected");
      if (errored) {        
        throw new Error(results[0].reason);
      }          
    },   

    renderLayerVisiblity(serviceLayer) {            

      if (serviceLayer.url && serviceLayer.loadPromise) {    
        
        this.enforceServiceLayerOrder(serviceLayer);        

        serviceLayer.sublayers.forEach((vueChild) => {                
          let agsChild = serviceLayer.agsLayer.sublayers.find(agsSubLayer => agsSubLayer.id === vueChild.id);
          this.recurseAgsLayerFromVueLayer(vueChild, agsChild);
        })           
      }
      
      this.recurseServiceLayerVisibility(serviceLayer);                  
    },

    recurseSubLayerVisiblity(subLayer) {
      if (!subLayer.sublayers) {
        if (subLayer.rootLayer.type === "arcGIS") {
          if (subLayer.fieldsPromise) {
            subLayer.agsLayer.visible = subLayer.visible;
          }
        } 
        else if (subLayer.loadPromise) {
          if (!subLayer.rootLayer.visible) {
            subLayer.agsLayer.visible = false;
          } else {
            subLayer.agsLayer.visible = subLayer.visible;
          }
        }            
      } else {                
        if (subLayer.agsLayer) {
          subLayer.agsLayer.visible = subLayer.visible;
        }
        subLayer.sublayers.forEach(subLayer => {
          this.recurseSubLayerVisiblity(subLayer);
        })      
      }      
    },

    recurseServiceLayerVisibility(serviceLayer) {
      if (serviceLayer.type === "arcGIS" && serviceLayer.loadPromise && serviceLayer.legendPromise) {
        serviceLayer.agsLayer.visible = serviceLayer.visible;
      } 

      if (serviceLayer.allSublayers) {
        serviceLayer.allSublayers.forEach(subLayer => {
          this.recurseSubLayerVisiblity(subLayer);
        })      
      }
    },

    async setLayerVisible(layer, isVisible) {       

      let oldNonVisibleActiveSubLayers = this.nonVisibleActiveSubLayers.slice();

      if(isVisible) {
        // Make all ancestors visible.
        let parent = layer.parent;                

        while (parent) {
          parent.visible = true;                                   
          parent = parent.parent;
        }                                                         
      } 

      layer.visible = isVisible;

      let newNonVisibleActiveSubLayers = this.nonVisibleActiveSubLayers.slice();
      let hiddenLayers = newNonVisibleActiveSubLayers.diff(oldNonVisibleActiveSubLayers);
      hiddenLayers.forEach(subLayer => {
        this.deleteSubLayerNavItems(subLayer);        
      })

      let serviceLayer = layer.rootLayer ? layer.rootLayer : layer;            
      await this.loadServiceLayerDeps(serviceLayer);      
      this.renderLayerVisiblity(serviceLayer);
    },           

    recurseLayers(fn, layer) {
      fn(layer);
      if (layer.sublayers) {
        layer.sublayers.forEach(child => {
          this.recurseLayers(fn, child);
        })      
      }
    },
    
    deleteSubLayerNavItems(subLayer) {
      if (subLayer.features) {
        subLayer.features.filter(feature => !feature.isSearchResult).forEach(feature => {
          this.deleteNavItemFeature(feature);
        })
      }   
    },
    
    async gotoCurrentPosition(){        
        
      let position = await getGeoLocationCurrentPosition();      
      let point = latLonToAgsPoint(this.agsPoint, position.coords.latitude, position.coords.longitude, this.mapView.spatialReference);
      await this.mapView.goTo({ target: point });                      
    },    
  },
  
  async created() {                       

    this.agsFeatureToVueFeature = new Map();
    
    await this.getBase64ImageDataUrl("./locatorhub-pin.png").then(imageDataUrl => {      
      this.addressLegendItem = {
        label: "",
        imageDataUrl: imageDataUrl       
      };    
    })    
    
    this.osSearches = this.getOSSearches();
    this.locatorSearches =  this.getLocatorSearches();
    //await Promise.all(this.locatorSearches.map(locatorSearch => this.loadLocatorFields(locatorSearch)));    locatordisabled

    loadScript();
    
    loadModules([
      "esri/Map",
      "esri/layers/TileLayer",
      "esri/layers/MapImageLayer",
      "esri/views/MapView",
      "esri/views/SceneView",
      "esri/Basemap",      
      "esri/core/promiseUtils",             
      "esri/geometry/SpatialReference",      
      "esri/rest/support/IdentifyParameters",
      "esri/rest/identify",
      "esri/geometry/Geometry",
      "esri/geometry/Point",
      "esri/geometry/Polygon",
      "esri/geometry/Polyline",      
      "esri/geometry/Circle",
      "esri/geometry/support/jsonUtils",
      "esri/Graphic",
      "esri/layers/GraphicsLayer",      
      "esri/WebScene",      
      "esri/rest/support/Query",
      "esri/rest/query",
      "esri/layers/FeatureLayer",
      "esri/symbols/SimpleMarkerSymbol",
      "esri/symbols/PictureMarkerSymbol", 
      "esri/symbols/TextSymbol", 
      "esri/symbols/SimpleLineSymbol",
      "esri/symbols/SimpleFillSymbol",
      "esri/symbols/PictureFillSymbol",
      "esri/symbols/support/jsonUtils",
      "esri/Viewpoint",
      "esri/config",
      "esri/core/urlUtils",            
      "esri/widgets/Sketch/SketchViewModel",
      "esri/layers/support/TileInfo",
      "esri/geometry/geometryEngine",      
      "esri/rest/support/PrintTemplate",
      "esri/rest/support/PrintParameters",
      "esri/rest/print",      
    ]).then(
      ([
        agsMap,
        TileLayer,
        MapImageLayer,
        MapView,
        SceneView,
        Basemap,        
        promiseUtils, 
        SpatialReference,        
        IdentifyParameters,
        Identify,
        Geometry,
        Point,
        Polygon,
        Polyline,     
        Circle,
        GeometryJsonUtils,
        Graphic,
        GraphicsLayer,        
        WebScene,                      
        Query,
        QueryTask,
        FeatureLayer,
        SimpleMarkerSymbol,
        PictureMarkerSymbol,
        SymbolJsonUtils, 
        TextSymbol,
        SimpleLineSymbol,         
        SimpleFillSymbol,
        PictureFillSymbol,                
        Viewpoint,
        esriConfig,
        urlUtils,        
        SketchViewModel,
        TileInfo,
        GeometryEngine,        
        PrintTemplate,
        PrintParameters,
        Print,        
        // Portal, OAuthInfo, identityManager
      ]) => {

        this.agsMapView = MapView;        
        this.agsCircle = Circle,
        this.agsGeometryJsonUtils = GeometryJsonUtils,     
        this.agsPoint = Point;
        this.agsPolygon = Polygon;
        this.agsPolyline = Polyline;
        this.agsMapImageLayer = MapImageLayer;        
        this.agsIdentifyParameters=IdentifyParameters;
        this.agsIdentify=Identify;
        this.agsGraphic=Graphic;
        this.agsGraphicsLayer=GraphicsLayer;        
        this.agsWebScene=WebScene;        
        this.agsQuery=Query;
        this.agsQueryTask=QueryTask;
        this.agsFeatureLayer=FeatureLayer;

        this.agsSimpleMarkerSymbol = SimpleMarkerSymbol;
        this.agsPictureMarkerSymbol = PictureMarkerSymbol;
        this.agsTextSymbol = TextSymbol;
        this.agsSimpleLineSymbol = SimpleLineSymbol;        
        this.agsSimpleFillSymbol = SimpleFillSymbol;        
        this.agsPictureFillSymbol = PictureFillSymbol;
        this.agsSymbolJsonUtils = SymbolJsonUtils;
        this.agsPromiseUtils = promiseUtils;
        this.agsViewpoint = Viewpoint;          
        this.agsSketchViewModel = SketchViewModel;
        this.agsViewpoint = Viewpoint;               
        this.agsTileInfo = TileInfo;   
        this.agsGeometryEngine = GeometryEngine;        		    
        this.agsPrintTemplate = PrintTemplate,
        this.agsPrintParameters = PrintParameters,
        this.agsPrint = Print;               

        this.recurseLayersInit = function recurseLayersInit(allVueSubLayers, allArcGISSubLayers, vueSubLayer, arcGISSubLayer) {
          
          const subLayerItems = [];

          if (arcGISSubLayer.subLayerIds) {
            arcGISSubLayer.subLayerIds.forEach(arcGISChildId => { 

              let arcGISChild = allArcGISSubLayers.find(function(child) {
                return child.id === arcGISChildId;
              });

              if (arcGISChild)
              {
                let vueChild = allVueSubLayers.find(item => { return item.id === arcGISChildId });                
                this.recurseLayersInit(allVueSubLayers, allArcGISSubLayers, vueChild, arcGISChild);
                
                vueChild.parent = vueSubLayer;

                subLayerItems.push(vueChild);                           
              }
            });

            vueSubLayer.sublayers = subLayerItems.reverse();  // So that vue sublayer order is the same as the same as when the ARCGIS api is used.
          } else {
            vueSubLayer.sublayers = null;
          }          
        }

        let promises = [];

        this.processResponse = function(response,index) {                  

            if (response.data.layers) {                      

              let layerConfig = response.data.layerConfig;              

              let vueLayer = {                     
                type: "arcGIS",       
                arcGISPath: layerConfig.arcGISPath,
                id: layerConfig.id.toString(),                
                title: layerConfig.title,
                url: this.arcGISBaseUrl + '/' + layerConfig.arcGISPath,
                expandedInAllTree: false,
                expandedInFavsTree: true, 
                expandedInVisibleTree: true,                                
                loadPromise: null,
                legendPromise: null,
                visible: false,
                agsLayer: null,
                isSelected: false
              };

              this.defineLayerProperties(vueLayer);              

              let arcGISLayer = {
                subLayerIds: []
              }

              let allVueSubLayers = [];          
              
              response.data.layers.forEach((arcGISSubLayer) => {
                if (arcGISSubLayer.parentLayerId === -1) {
                  // Fix an unusual occurance of invalid data where 
                  // parent layer does not refer to a sub layer and should.
                  arcGISLayer.subLayerIds.push(arcGISSubLayer.id)
                }

                let subLayerConfig = layerConfig.subLayers.find(subLayer => subLayer.id === arcGISSubLayer.id);                

								let vueSubLayer = {
                  id: arcGISSubLayer.id,                                    
                  title: arcGISSubLayer.name,    
                  parent: null,
									minScale: arcGISSubLayer.minScale,
									maxScale: arcGISSubLayer.maxScale,                                 
									visible: false,    
									favourite: false,            									
                  rootLayer: vueLayer,
                  legend: null,
                  fields: null,
                  objectIdPropertyName: "",                  
                  features: [],                                 
                  expandedInAllTree: false,
                  expandedInFavsTree: true, 
                  expandedInVisibleTree: true,                  
                  identifyResultsSortedField: null,
                  identifyResultsSortedAscending: true,
                  identifyResultsSearchSortedField: null,
                  identifyResultsSearchSortedAscending: true,
                  includeInLayerManager: true,
                  fieldsPromise: null,       
                  loadPromise: null,           
                  search: (subLayerConfig && subLayerConfig.query) ? subLayerConfig.query : null,
                  agsLayer: null,                  
                  meta: null,
                  url: import.meta.env.VITE_MBAPI_EndPoint + '/' + vueLayer.arcGISPath + '/' + arcGISSubLayer.id,
                  isSelected: false
                }

                if (subLayerConfig) {
                  vueSubLayer.includeInLayerManager = subLayerConfig.includeInLayerManager;
                }

                this.defineLayerProperties(vueSubLayer);               
                allVueSubLayers.push(vueSubLayer);
              })                                          

              vueLayer.allSublayers = allVueSubLayers;
              this.recurseLayersInit(vueLayer.allSublayers, response.data.layers, vueLayer, arcGISLayer);              
              this.layers.push(vueLayer);                        
            }
        }        
            
        this.layersConfig.forEach((layerConfig, index) => {                
          let url = this.arcGISBaseUrl + "/" + layerConfig.arcGISPath + "?f=json";                   
          let get = axios.get(url, {      
            transformResponse: axios.defaults.transformResponse.concat((data) => {
              data.layerConfig = layerConfig;
              return data;
            })            
          });

          get.catch(e => {
            e.url = url;
            throw e;
          })         

          promises.push(get);
        })               

        Promise.all(promises).then((results) =>
        {       
          results.forEach((response, index) =>
          {             
            if (response.error || response.data.error) {
              let error = new Error("Error retrieving layer data");
              if (response.data) {
                error.message += ". " + response.data.error.message + ": " + response.request.responseURL;                
                throw error;
              }
            } else
            {              
              this.processResponse(response, index);
            }
          })   

          this.addPrivateLayersGroup();
          this.addUserFeaturesLayer();                

          let layers = [];

          let baseLayers = this.basemaps.forEach(basemap => {                        
         
            if (basemap.extendedScalesUrl) {
              let extendedLayer = new MapImageLayer({ 
                                    id: basemap.id + "_extended", 
                                    url: basemap.extendedScalesUrl,
                                    visible: false                        

                                  })
              layers = [...layers, extendedLayer];
            }

            let tileLayer = new TileLayer({ 
              id: basemap.id, 
              url: this.arcGISBaseUrl + '/' + basemap.arcGISPath,
              visible: basemap.visible,
              opacity: basemap.opacity,
              title: basemap.title,
              copyright: basemap.copyright
            })              
            layers = [...layers, tileLayer];
          });
          
          if (this.useExtendedScales) {
            this.availableScales = [100, 200, 300, 400, 500, 1000, 1250, 2000, 2500, 4000, 8000, 16000, 32000, 64000, 128000, 256000, 512000];
          }

          const basemap = new Basemap({baseLayers:layers});
          
          let osVector = layers.find(layer => layer.title === "OS Vector");
          if (osVector) {
            this.activeOverviewBasemap = osVector;
          }          

          //init map
          let map = new agsMap({
            basemap: basemap,           
          });       

          this.$set(this.$root, "mbMap", map);
          
          //init view parameters, some temp properties 
          //as map position will be set by service or user payload.          

          const viewParams ={
            
            map: this.map,
            container: null,
            ui: {
              components: []
            },            
            constraints: {                         
              lods: this.agsTileInfo.create({ 
                scales: this.availableScales,
                spatialReference: { wkid: 27700 },
              }).lods
            }
            //viewingMode: "local" //does this do anything?
          }          

          //create MapView
          let mapView = new MapView(viewParams);
          this.$set(this.$root, "mbMapView", mapView);
          this.mapView.container = this.container;          

          this.mapView.when(async _ => {                                                              
      
            this.searchInclusionExtent = new this.agsExtent(this.mapExtent.left, this.mapExtent.bottom, this.mapExtent.right, this.mapExtent.top, this.mapView.spatialReference);                                    
            this.mapView.constraints.geometry = this.searchInclusionExtent;

            // Esri move all nodes defined under its mapView container to a esri-user-storage node
            // Move these to esri-view-root.            
            let componentsContainer = document.querySelector("#mappingBrowserContainer > .esri-view-user-storage > .mappingBrowserComponentsContainer");
            let mappingBrowserViewRoot = document.querySelector("#mappingBrowserContainer > .esri-view-root");						

            mappingBrowserViewRoot.appendChild(componentsContainer);                            
            
            this.bookmarkSymbol = {
              type: "picture-marker",
              url: "./bookmark.png",
              width: 12,
              height: 22,
              yoffset: 6,
              xoffset: 0
            };      

            this.addressSymbol = {
              type: "picture-marker",
              url: "./locatorhub-pin.png",
              width: 12,
              height: 12,              
            };      
            
            this.mapPinSymbol = {
              type: "picture-marker",
              url: "./map-pin-blue.png",
              width: 32,
              height: 32,
              yoffset: 16,
              xoffset: 0
            };      
            
            this.searchFeaturesGfxLayer = new this.agsGraphicsLayer({
              id: "searchFeaturesGfxLayer",
              title: "searchFeaturesGfxLayer"
            });
            this.map.layers.add(this.searchFeaturesGfxLayer);        

            this.userFeaturesGfxLayer = new this.agsGraphicsLayer({
              id: "userFeaturesGfxLayer",
              title: "userFeaturesGfxLayer"
            });
            this.map.layers.add(this.userFeaturesGfxLayer);           

            this.userFeaturesLabelsGfxLayer = new this.agsGraphicsLayer({
              id: "userFeaturesLabelsGfxLayer",
              title: "userFeaturesLabelsGfxLayer"
            });
            this.map.layers.add(this.userFeaturesLabelsGfxLayer);        
            
            this.measurementsGfxLayer = new this.agsGraphicsLayer({
              id: "measurementsGfxLayer",
              title: "measurementsGfxLayer"
            });
            this.map.layers.add(this.measurementsGfxLayer);       

            this.measurementsLabelsGfxLayer = new this.agsGraphicsLayer({
              id: "measurementsLabelsGfxLayer",
              title: "measurementsLabelsGfxLayer"
            });
            this.map.layers.add(this.measurementsLabelsGfxLayer);       

            this.annotationLinesGfxLayer = new this.agsGraphicsLayer({
              id: "annotationLinesGfxLayer",
              title: "annotationLinesGfxLayer"
            });
            this.map.layers.add(this.annotationLinesGfxLayer);  

            this.annotationEndsGfxLayer = new this.agsGraphicsLayer({
              id: "annotationEndsGfxLayer",
              title: "annotationEndsGfxLayer"
            });
            this.map.layers.add(this.annotationEndsGfxLayer);                                                        

            this.annotationLabelsGfxLayer = new this.agsGraphicsLayer({
              id: "annotationLabelsGfxLayer",
              title: "annotationLabelsGfxLayer"
            });
            this.map.layers.add(this.annotationLabelsGfxLayer);          
            
            this.svmUpdateLayer = new this.agsGraphicsLayer({
              id: "svmUpdateLayer",
              title: "svmUpdateLayer"
            });
            this.map.layers.add(this.svmUpdateLayer);                    
            
            this.selectedSystemFeaturesGfxLayer = new this.agsGraphicsLayer({
              id: "selectedSystemFeaturesGfxLayer",
              title: "selectedSystemFeaturesGfxLayer"
            });
            this.map.layers.add(this.selectedSystemFeaturesGfxLayer);           

            this.svmCreateLayer = new this.agsGraphicsLayer({
              id: "svmCreateLayer",
              title: "svmCreateLayer"
            });
            this.map.layers.add(this.svmCreateLayer);                    
            
            this.identifyGfxLayer = new GraphicsLayer({
                id: "identifyGfxLayer",
                title: "identifyGfxLayer",
                listMode: "hide"
            });
            this.map.layers.add(this.identifyGfxLayer);                                

            this.mapPinGfxLayer = new this.agsGraphicsLayer({
              id: "mapPinGfxLayer",
              title: "mapPinGfxLayer"
            });
            this.map.layers.add(this.mapPinGfxLayer);       

            this.bookmarksGfxLayer = new this.agsGraphicsLayer({
              id: "bookmarksGfxLayer",
              title: "bookmarksGfxLayer"
            });
            this.map.layers.add(this.bookmarksGfxLayer);       

            this.updateSvm = new this.agsSketchViewModel(
            {         
              updateOnGraphicClick :false,              
              view: this.mapView,               
              layer: this.svmUpdateLayer,                    
            });                     

            this.createSvm = new this.agsSketchViewModel(
            {         
              updateOnGraphicClick :false,              
              view: this.mapView,               
              layer: this.svmCreateLayer,          
            });                     
                  
            this.updateLabelsGfxLayer = new this.agsGraphicsLayer({
              id: "updateLabelsGfxLayer",
              title: "updateLabelsGfxLayer"
            });

            this.map.layers.add(this.updateLabelsGfxLayer);       

            this.updateAnnotationLabelsGfxLayer = new this.agsGraphicsLayer({
              id: "updateAnnotationLabelsGfxLayer",
              title: "updateAnnotationLabelsGfxLayer"
            });

            this.map.layers.add(this.updateAnnotationLabelsGfxLayer);         
            this.createSvm.on("create", this.onFeatureCreated);                        
            this.createSvm.on("undo", this.onFeaturesUndo);
            this.updateSvm.on("update", this.onFeaturesUpdated);            
            this.updateSvm.on("delete", this.onFeaturesDeleted);            

            this.updateBookmarksOnMap();                     
 
            this.mapView.on("mouse-wheel", async e => {
              this.mapView.constraints.snapToZoom = true;
            });
            
            this.mapView.watch("scale", (newValue, oldValue, propertyName) => {   
              this.onMapScaleChanged();  
            })                                          

            this.mapView.on("double-click", e => {                    
              // Disable double click to zoom              
              e.stopPropagation();
            })

            this.mapView.on("pointer-move", e => {              
              this.lastPointerPos = this.mapView.toMap(e);                           
              
              if (this.contextMenuTimeOut) {                
                // finger might move a only a tiny bit whilst holding down.  Cater for this.
                let lastClickedScreen = this.mapView.toScreen(this.lastPointerDownMapPoint);                
                let distance = distanceBetweenPoints(e.x, e.y, lastClickedScreen.x, lastClickedScreen.y);
                if(distance < 0) distance *= -1;

                let tolerance = 10;
                if (distance > tolerance) {
                  if (this.contextMenuTimeOut) {
                    this.contextMenuPopoverVisible = false; 
                    clearTimeout(this.contextMenuTimeOut);
                    this.contextMenuTimeOut = null;
                  }    
                }
              }           
            });                        

            this.mapView.on("drag", ["Shift"], (e) => {
              e.stopPropagation();
            });

            this.mapView.on("drag", ["Shift", "Control"], (event) => {
              e.stopPropagation();
            });                      
            
            this.mapView.on("pointer-down", e => {                                              
              this.mapPointerDown(e);
            })
               
            this.mapView.on("pointer-up", e => {                    
              this.mapPointerUp(e);
            });                                   

            this.subLayerSearches =  this.getSubLayerSearches();

            let searchItems = this.searchItems.sort((itemA, itemB) =>{
              return itemA.title.localeCompare(itemB.title);
            })
            this.selectedSearchItemId = searchItems[0].id;
                             
            this.updateViewPadding();        
            
            this.goto({extent: this.searchInclusionExtent});
            this.exportPresentedForceUpdate = !this.exportPresentedForceUpdate;
            this.loaded = true;                      
            this.$emit("loaded");        
          }, mapViewWhenError => {
            this.errorTitle = "Could not initialise map view"
            this.errorMessage = mapViewWhenError.message;
            this.$refs.ErrorDialog.show();
            this.$emit("initCancelled");                       
          })
        }).catch(e => {           
                   
          this.errorTitle = "Could not retrieve layer data.  Server maybe unavailable"
          this.errorMessage = e.url;
          this.$refs.ErrorDialog.show();
          this.$emit("initCancelled");           
          throw e;
        })
      }      
    );
  },  

  mounted() {  
    if (this.contextMenuPopoverVisible) {
      this.showContextMenu();
    }
  }
};
</script>

<style lang="scss">
@import "./../variables";
 #mappingBrowserContainer {
   overflow: hidden;
   height: 100%;   

   .pointer-events-none {
     pointer-events: none;
   }

   .pointer-events-auto {
     pointer-events: auto;
   }

   &.esri-view .esri-view-surface[data-cursor="crosshair"] {
     cursor: auto !important
   }

  &.esri-view .esri-view-surface[data-cursor="pointer"] {
     cursor: auto !important
   }

  .fullScreenButton {
    position: absolute;    
    display:flex;
    justify-content: center;
    right: 4px;
    bottom: 4px;
    width: 18px;
    height: 18px;
    background: #222;
    border: none;        
    color: #aaa;    
    font-size: 0.8rem;    
   }

   .mappingBrowserComponentsContainer {    

    height: 100%;        

    .topLeftWidgets {
      position: absolute;
      left:14px;
      top:10px;         
      transition: opacity 0.5s;          
    }

    .bottomCenterWidgets {
      position: absolute;
      left: 50%;      
      bottom: 0px;
      transform: translateX(-50%);
    }

    .topRightWidgets {
      position: absolute;
      right:14px;
      top:10px;         
      transition: opacity 0.5s;          
    }

    .bottomLeftWidgets {
      position: absolute;
      left:16px;
      bottom:16px;                        
    }

    .topLeftWidgets :hover{
      opacity: 1;
    }

    .topRightWidgets :hover{
      opacity: 1;
    }

    .componentsContainerWithPadding {
      position: relative;	
      height: 100%;                
    
      .componentsContainerInner {
        position: relative;        
        height: 100%;              
      }   
    }           
  }
}

</style>

